import React from 'react';
import PonudbaCard from '../shared/PonudbaCard'; // Import the PonudbaCard component
import './PonudbaSection.css'; // Import your CSS file

function PonudbaSection() {

  const podatki1 = ["Hipnoterapija (1,5h)", '"Čudovita" Samohipnoza'];
  const podatki2 = ["6x hipnoterapija (30 - 40min)", "Samohipnoza", "Personaliziran - transformacijski posnetek v avdio obliki"];
  const podatki3 = ["3x hipnoterapija (1 - 1.5h)", "Samohipnoza", "Personaliziran - transformacijski posnetek v avdio obliki"];

  return (
    <div className="ponudba-section">
    <hr className='hr-styled' />
      <h2 className='dejavtitle dancingfont'>Oglejte si mojo ponudbo</h2>
      <div className="card-container h-100">
        {/* First Card */}
        <PonudbaCard
          title="Samostojni termini"
          subtitle="Lahko izbirate med: "
          description={podatki1}
        />
        {/* Second Card */}
        <PonudbaCard
          title='Paket Ožičeno'
          subtitle="Kaj vam nudi paket?"
          description={podatki2}
          price="250€"
        />
        {/* Third Card */}
        <PonudbaCard
          title="Paket Čustveno Čiščenje"
          subtitle="Kaj vam nudi paket?"
          description={podatki3}
          price="200€"
        />
      </div>
      <p className='obvestilo'><strong>Hipnoza se uporablja kot dopolnitev k vašemu boljšemu počutju in višji motiviranosti in nikakor ne kot nadomestilo h kateremukoli postopku vašega zdravljenja!</strong></p>
      <hr className='hr-styledb' />
    </div>
  );
}

export default PonudbaSection;
