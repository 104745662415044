import React from "react";
import Cenik from "../components/ponudba/Cenik";
import { Helmet } from "react-helmet";

function Ponudba(){
    return (
        <>
        <Helmet>
            <title>Ponudba</title>
            <meta name="description" content="Oglejte si storitve hipnoterapije. Ponujam različne terapije za izboljšanje vašega mentalnega zdravja, počutja ter načina razmišljanja." />
            <meta name="keywords" content="Ponudba, Maja Dobravec, Hipnoterapija, Storitve hipnoze, Terapija, Mentalno zdravje, Cenik storitev, Terapija s hipnozo" />
            <meta property="og:title" content="Ponudba" />
            <meta property="og:description" content="Oglejte si naše storitve hipnoterapije pri Maji Dobravec. Ponujam različne terapije za izboljšanje vašega mentalnega zdravja, počutja ter načina razmišljanja." />
            <meta property="og:url" content="https://www.hipnoterapijamajad.si/ponudba" />
            <meta property="og:type" content="website" />
        </Helmet>
        <Cenik />
    </>

        
    )
}

export default Ponudba;