import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import "./KontaktContent.css";

function KontaktContent() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    subject: '',
    content: '',
  });

    // New state for loading and message
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    const apiUrl = 'https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/send-contact'; // Replace with your API Gateway endpoint

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Form submitted with data:', formData);
      console.log('Server response:', result);
      setMessage('Sporočilo poslano uspešno!');

      // Optionally, clear the form
      setFormData({
        name: '',
        surname: '',
        email: '',
        subject: '',
        content: '',
      });

    } catch (error) {
      console.error('There was an error submitting the form:', error);
      setMessage('Napaka pri pošiljanju.');
    }finally {
      setLoading(false);
    }
  };

  return (
    <div className="postavitev contact-container">
      <div className="contact-info">
        <h1 className="dancingfont"><strong>Pošljite Povpraševanje</strong></h1>
        <p className="mb-2">Skozi moje življenje so me obkrožale stiske ljudi in še vedno me, ki se soočajo  s čustvenimi blokadami, razne psihične tegobe, stanjem po možganski kapi in razne odvisnosti.</p>
        <p>Če ste prispeli do teh vrstic, ki jih prebirate sedaj,
          naredite prvi korak k trajnim pozitivnim spremembam s pomočjo hipnoterapije!</p>
        <h1 className="dancingfont"><strong>Kontaktne Informacije</strong></h1>
        <h5 className="mt-3"><FontAwesomeIcon className="icon" icon={faPhone} /> 051 275 721</h5>
        <h5 className="mt-3"><FontAwesomeIcon className="icon" icon={faEnvelope} /> info@hipnoterapijamajad.si</h5>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <h1 className="dancingfont"><strong>Kontaktni Obrazec</strong></h1>
          <div className="form-group">
            <label>Ime:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Priimek:</label>
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Zadeva:</label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Vsebina:</label>
            <textarea
              name="content"
              value={formData.content}
              onChange={handleChange}
            ></textarea>
          </div>
          <button type="submit" className="button padd w-100"> {loading ? 'Pošiljanje...' : 'Pošlji'}</button>
          <div className="message">{message}</div>
        </form>
      </div>
    </div>
  );
}

export default KontaktContent;