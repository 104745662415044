import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import imageSrc1 from '../../assets/images/logo.png';
import './NavbarNorm.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function NavbarNorm(){

  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
      setExpanded(!expanded);
  };

  const closeNavbar = () => {
      setExpanded(false);
  };

    return(
    <Navbar expand="lg" data-bs-theme="dark" className="navbar-add navbar-fixed-top bg-body-tertiary" expanded={expanded}>
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="logo"><img className="logo" src={imageSrc1} alt="" /></Navbar.Brand>
        <Navbar.Toggle onClick={toggleNavbar} aria-controls="basic-navbar-nav">
        <FontAwesomeIcon icon={faBars} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end navbar-collapsed-content">
          <Nav >
            <Nav.Link as={Link} to="/" onClick={closeNavbar}>Home</Nav.Link>
            <Nav.Link as={Link} to="/omeni" onClick={closeNavbar}>O meni</Nav.Link>
            <Nav.Link as={Link} to="/hipnoterapija" onClick={closeNavbar}>Hipnoterapija</Nav.Link>
            <Nav.Link as={Link} to="/blog" onClick={closeNavbar}>Blog</Nav.Link>
            <Nav.Link as={Link} to="/raziskave" onClick={closeNavbar}>Raziskave</Nav.Link>
            <Nav.Link as={Link} to="/ponudba" onClick={closeNavbar}>Cenik</Nav.Link>
            <Nav.Link as={Link} to="/kontakt" onClick={closeNavbar}>Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default NavbarNorm;