import React, { useState } from 'react';
import './BlogForm.css';

function BlogForm() {
    const [blog, setBlog] = useState({
        title: '',
        creationDate: '',
        imageSrc: '',
        documentSrc: '',
        content: [{ subtitle: '', paragraphs: [''] }],
        category: '',
        readingTime: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBlog({ ...blog, [name]: value });
    };

    const handleContentChange = (sectionIndex, e) => {
        const { name, value } = e.target;
        const updatedContent = [...blog.content];

        if (name === 'paragraphs') {
            // Assuming a single paragraph per section for simplicity
            updatedContent[sectionIndex][name] = [value];
        } else {
            updatedContent[sectionIndex][name] = value;
        }

        setBlog({ ...blog, content: updatedContent });
    };

    const addContentSection = () => {
        setBlog({
            ...blog,
            content: [...blog.content, { subtitle: '', paragraphs: [''] }]
        });
    };

    const removeLastContentSection = () => {
        if (blog.content.length > 1) { // Ensure at least one content section remains
            const updatedContent = blog.content.slice(0, -1);
            setBlog({ ...blog, content: updatedContent });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (!file) return;

        // Store the file object in the state for later upload
        setBlog({ ...blog, imageFile: file }); // Add a new state property for the file object
        console.log(blog);
    };
    const handleDocumentChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (!file) return;

        // Store the file object in the state for later upload
        setBlog({ ...blog, documentFile: file }); // Add a new state property for the file object
        console.log(blog);
    };

    /*const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Get the selected file
        console.log(file);
        if (!file) return;

        // Fetch presigned URL from your backend
        try {
            const presignedUrlResponse = await fetch('http://127.0.0.1:3000/upload-image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ fileName: file.name, fileType: file.type }),
            });
            const { url } = await presignedUrlResponse.json();

            // Upload the file to S3
            const uploadResponse = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                body: file,
            });

            if (uploadResponse.ok) {
                const imageUrl = url.split('?')[0]; // URL without query parameters
                setBlog({ ...blog, imageSrc: imageUrl }); // Update state with the image URL
            } else {
                throw new Error('Failed to upload image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };*/


    const validateImageFile = (file) => {
        return !file || (file && file.type.startsWith('image/'));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Indicate submission start

        // Reset validation errors
        setValidationErrors({});

        // Validation
        let errors = {};

        // Validate the image file if one has been selected
        if (!validateImageFile(blog.imageFile)) {
            errors.imageFile = 'Please upload a valid image file.';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            setIsSubmitting(false);
            return; // Stop submission if there are validation errors
        }


        // Upload the image file first
        if (blog.imageFile) {
            try {
                //const presignedUrlResponse = await fetch('http://127.0.0.1:3000/upload-image', {
                    const presignedUrlResponse = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/upload-image', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileName: blog.imageFile.name, fileType: blog.imageFile.type }),
                });
                const { url } = await presignedUrlResponse.json();

                // Upload the file to S3
                const uploadResponse = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': blog.imageFile.type,
                    },
                    body: blog.imageFile,
                });

                if (uploadResponse.ok) {
                    const imageUrl = url.split('?')[0]; // URL without query parameters
                    // Update blog with the image URL before submitting
                    blog.imageSrc = imageUrl;
                } else {
                    throw new Error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsSubmitting(false); // Reset submission status
                return; // Stop the submission if the image upload fails
            }
        }


        // Upload the image file first
        if (blog.documentFile) {
            try {
                //const presignedUrlResponse = await fetch('http://127.0.0.1:3000/upload-image', {
                const presignedUrlResponse = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/upload-image', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileName: blog.documentFile.name, fileType: blog.documentFile.type }),
                });
                const { url } = await presignedUrlResponse.json();

                // Upload the file to S3
                const uploadResponse = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': blog.documentFile.type,
                    },
                    body: blog.documentFile,
                });

                if (uploadResponse.ok) {
                    const documentUrl = url.split('?')[0]; // URL without query parameters
                    // Update blog with the document URL before submitting
                    blog.documentSrc = documentUrl;
                } else {
                    throw new Error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsSubmitting(false); // Reset submission status
                return; // Stop the submission if the image upload fails
            }
        }




        try {
            // Create a new Date object representing the current date
            const currentDate = new Date();

            // Format the date string in the format 'Month day, year', e.g. 'Nov 30, 2023'
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthIndex = currentDate.getMonth(); // Month index starts from 0
            const year = currentDate.getFullYear();
            const day = String(currentDate.getDate()).padStart(2, '0');

            const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;

            // Add the formatted date to the blog object
            blog.creationDate = formattedDate;
            await submitBlog(blog); // Assuming this function is async
            setBlog({
                title: '',
                creationDate: '',
                imageSrc: '',
                documentSrc: '',
                content: [{ subtitle: '', paragraphs: [''] }],
                category: '',
                readingTime: '',
            });
            alert('Blog submitted successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting blog');
        }
        setIsSubmitting(false); // Reset submission status
    };

    const submitBlog = async (blogData) => {

        try {
            //const response = await fetch('http://127.0.0.1:3000/submit-blog-post', { // Replace 'YOUR_API_ENDPOINT' with your actual endpoint URL
            const response = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/submit-blog-post', {   
            method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(blogData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data); // Handle the response data
            alert('Blog submitted successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting blog');
        }
    };

    return (
        <div className='blog-form'>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label>Naslov:</label>
                    <input type="text" name="title" value={blog.title} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Kategorija:</label>
                    <input
                        type="text"
                        name="category"
                        value={blog.category}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label>Čas branja (minute):</label>
                    <input
                        type="number"
                        name="readingTime"
                        value={blog.readingTime}
                        onChange={handleInputChange}
                        min="1"
                    />
                </div>
                <div className="form-group">
                    <label>Naloži sliko:</label>
                    <input type="file" onChange={handleFileChange} />
                    {validationErrors.imageFile && <div className="error">{validationErrors.imageFile}</div>}
                </div>
                <div className="form-group">
                    <label>Naloži dokument:</label>
                    <input type="file" onChange={handleDocumentChange} />
                </div>
                {blog.content.map((section, index) => (
                    <div key={index} className="content-section">
                        <div className="form-group">
                            <label>Podnaslov:</label>
                            <input type="text" name="subtitle" value={section.subtitle} onChange={(e) => handleContentChange(index, e)} />
                        </div>
                        <div className="form-group">
                            <label>Odstavek:</label>
                            <textarea name="paragraphs" value={section.paragraphs[0]} onChange={(e) => handleContentChange(index, e)} />
                        </div>
                    </div>
                ))}
                <div className="form-actions">
                    <button type="button" onClick={addContentSection}>Dodaj Odstavek</button>
                    <button type="button" onClick={removeLastContentSection}>Odstrani Odstavek</button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Nalaganje...' : 'Naloži Blog'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default BlogForm;
