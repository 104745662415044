import React from 'react';
import DejavnostCardList from '../shared/DejavnostCardList';
import './DejavnostiSection.css';
import { Link } from 'react-router-dom';
import ContactButton from '../shared/ContactButton';


function DejavnostiSection() {
  return (
    <div className='dejavnosti-section '>
    <hr className='hr-styled'/>
    <h1 className='dejavtitle dancingfont'><strong>Moje dejavnosti</strong></h1>
    <h4 className='subtitle'>Hipnoza in ...</h4>
    <div className="cardlist">
        <DejavnostCardList />
      </div>
      <ContactButton />
      <Link to="/hipnoterapija" className="btn btn-light button ml-3 mt-3">Razdelek hipnoterapija</Link>
    </div>
  );
}

export default DejavnostiSection;