import React from "react";
import HeadTitle from "../components/about-me/HeadTitle";
import RaziskaveList from "../components/raziskave/RaziskaveList";
import RaziskaveFilters from "../components/raziskave/RaziskaveFilters";
import { Helmet } from "react-helmet";

function Raziskave(){
    return (
        <dir className="postavitev">
            <Helmet>
                <title>Raziskave</title>
                <meta name="description" content="Odkrijte naše raziskave in študije s področja hipnoterapije. Preberite najnovejše raziskave o učinkovitosti hipnoterapije in njenih koristih za mentalno zdravje." />
                <meta name="keywords" content="Raziskave, Hipnoterapija, Maja Dobravec, Študije, Mentalno zdravje, Terapevtske raziskave, Učinkovitost hipnoze, Zdravljenje s hipnozo, Znanstvene raziskave" />
                <meta property="og:title" content="Raziskave" />
                <meta property="og:description" content="Odkrijte naše raziskave in študije s področja hipnoterapije. Preberite najnovejše raziskave o učinkovitosti hipnoterapije in njenih koristih za mentalno zdravje." />
                <meta property="og:url" content="https://www.hipnoterapijamajad.si/raziskave" />
                <meta property="og:type" content="website" />
            </Helmet>
            <HeadTitle title={['Raziskave']} />
            <RaziskaveFilters />
            <RaziskaveList />
        </dir>
    )
}

export default Raziskave;