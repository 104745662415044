import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const Button = styled.button`
 display: ${props => props.show ? 'block' : 'none'}; /* Use block for smoother transitions */
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 2s ease-in-out, visibility 2s ease-in-out; /* Adjusted for smoother transition */
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #dcab70; /* Custom background color */
  color: white;
  cursor: pointer;
  width: 30px; /* Explicit width for a perfect circle */
  height: 30px; /* Explicit height for a perfect circle */
  border-radius: 50%; /* Ensures round shape */
  font-size: 18px;
  display: flex; /* Enables center alignment of content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  opacity: 0.5; /* Initial opacity */
  transition: opacity 0.6s, background-color 0.6s; /* Smooth transition */

  &:hover {
    background-color: #b98a56; /* Slightly darker shade for hover effect */
    color: white;
    opacity: 1; /* Full opacity on hover */
  }
`;

function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showButton && window.pageYOffset > 400) {
        setShowButton(true);
      } else if (showButton && window.pageYOffset <= 400) {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [showButton]);

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <Button onClick={scrollTop} show={showButton}>
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  );
}

export default ScrollToTopButton;
