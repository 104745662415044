import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation(); // Use useLocation to detect path changes

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // React to changes in location.pathname

  return null;
}

export default ScrollToTop;
