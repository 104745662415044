import React, { useEffect } from 'react';

const SitemapRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://hipnoterapija-md-bucket.s3.eu-central-1.amazonaws.com/sitemap_index.xml';
  }, []);

  return null;
};

export default SitemapRedirect;
