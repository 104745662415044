import React from 'react';

// Define the ExplanationCMS component
const ExplanationCMS = () => {
  // Styles for the container div
  const styles = {
    container: {
      padding: '20px',
      margin: '20px 200px',
      backgroundColor: '#f0f0f0', // Light grey background
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Slight shadow for depth
      fontSize: '16px', // Font size
      color: '#333', // Text color
    }
  };

  return (
    <div style={styles.container}>
      <h1 className='mb-4'>Sistem za upravljanje s podatki</h1>
      <h3>Prijava v sistem:</h3>
      <p>Na povezavi spletne strani dodate pot <strong>/hipnoterapijaAdmin</strong>. Odpre se stran za prijavo v sistem, kjer vpišete uporabniško ime in geslo. Vaša prijava v sistem je <strong>vejavna 30 minut</strong>, kar pomeni, da vas bo sistem prosil, da ponovno vpišete uporabniško ime in geslo po 30-ih minutah.</p>
      <h3>Navigacijska vrstica:</h3>
      <p className='mb-0'>V navigacijski vrstici so narejeni razdelki:</p>
      <ul>
        <li><strong>Začetna stran</strong> - ta vas popeje na trenutno stran z navodili</li>
        <li><strong>Blog List</strong> - vas popelje na stran, kjer so vidni vsi vnešeni blog članki</li>
        <li><strong>Dodaj Blog</strong> - stran namenjena nalaganju blog člankov</li>
        <li><strong>Raziskave List</strong> - vas popelje na stran, kjer so vidne vse vnešene raziskave</li>
        <li><strong>Dodaj Raziskavo</strong> - stran namenjena nalaganju raziskav</li>
        <li><strong>Odjava</strong> - odjava iz CMS strani</li>
      </ul>
      <h3>Dodajanje objave:</h3>
      <p>Pri dodajanju objave je pomembno, da predhodno pripravite besedilo, pripadajoče slike in dokument za objavo. Nato zapolnite vse polja in kliknete shrani.</p>
      <h3>Pregled in popravljanje objave:</h3>
      <p>Na straneh list so prikazane vse objave. Ob kliku na izbrano objavo, se le ta odpre v novem oknu in prikaže celotno vsebino. Nato imate opcijo izbrano objavo izbristi ali posodobiti. Ob posodobitvi se odprejo polja za posodbitev. Popravite/Dodate besedilo, ali pa naložite novo sliko/dokument in nato shranite spremembe. Vedno je med posodabljanjem opcija preklica. Posodobitve lahko izvedete večkrat.</p>
      <h3>E-novice:</h3>
      <p>Naročniki ob prijavi dobijo avtomatsko poslan mail. Nato pa so obveščeni vsakič, ko naložite nov blog članek.</p>
      <h3>Nasveti:</h3>
      <p>Pri izbiranju slik predlaga pravokotno obliko, da bo izgled v razmerju. Pri nalaganju dokumenta pa naj bodo le tej v PDF obliki. Pred prvo objavo bloga/raziskave pripravite vse kar potrebujete in vnesite vsa polja.</p>
    </div>
  );
};

export default ExplanationCMS;