import React from 'react';
import './BrownField.css'; // Import your CSS file for styling

function BrownField({ paragraphs, last }) {
  
  return (
    <div className="brown-field">
      {paragraphs.map((paragraph, index) => (
        <p key={index}> {last && index === paragraphs.length - 1 ? paragraph : <strong>{paragraph}</strong> }</p>
      ))}
    </div>
  );
}

export default BrownField;