import React from 'react';
import Card from './DejavnostCard';
import './DejavnostCardList.css';
import imageSrc1 from '../../assets/images/dejavnost1.png';
import imageSrc2 from '../../assets/images/dejavnost2.png';
import imageSrc3 from '../../assets/images/dejavnost3.png';
import imageSrc4 from '../../assets/images/dejavnost4.png';
import imageSrc5 from '../../assets/images/dejavnost5.png';



function DejavnostCardList() {
  return (
    <div className="card-list dejav">
      <Card
        image={imageSrc1}
        title="Čustvene blokade"
        description="Čustvene blokade se pogosto porodijo iz življenjskih pretresov, kot so izguba, travma
ali bolezen. Naša terapija nudi podporo pri prepoznavanju in preseganju teh globoko
zakoreninjenih ovir za vaš notranji mir in ravnovesje."
        tab="blokade"
      />
      <Card
        image={imageSrc2}
        title="Možganska kap"
        description="Hipnoza je lahko ključ do obnove telesne in čustvene uravnoteženosti po možganski
kapi, saj spodbuja fizično okrevanje, zmanjšuje tesnobo in otrplost, poveča apetit po
zdravi hrani ter daje motivacijo k boljšemu počutju in zdravju."
        tab="kap"
      />
      <Card
        image={imageSrc3}
        title="Pretirana skrb"
        description="Odpravite pretirano skrb s hipnoterapijo, ki umirja neprestano zaskrbljenost, lajša
potrebo po nadzoru in zmanjšuje vsakodnevni stres, da ponovno najdete notranji mir."
        tab="skrb"
      />
      <Card
        image={imageSrc4}
        title="Odpuščanje"
        description="Odpuščanje je darilo, ki ga podarite sami sebi - pot k notranjemu miru, ki ne zahteva
spreminjanja drugih. Z našo pomočjo odkrijte, kako se lahko osvobodite bremen
preteklosti in zaživite s polnejšim srcem.
Izstopite iz te kletke, v vaših rokah je ključ do izhoda!"
        tab="odpuscanje"
      />
      <Card
        image={imageSrc5}
        title="Alkoholizem"
        description="Ko se soočate z izzivi alkoholizma, bodisi osebno bodisi v družini, veste, kako
globoko lahko segajo te bolečine. Hipnoterapija ponuja orodje za tiste, ki se borijo z
odvisnostjo, omogoča jim lajšanje bolečine in podpira korake k okrevanju in novemu
začetku."
        tab="alkoholizem"
      />

      {/* Add more Card components as needed */}
    </div>
  );
}

export default DejavnostCardList;