import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const RaziskaveContext = createContext();

export const RaziskaveProvider = ({ children }) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState({ category: '', searchText: '' });

    // Method to update filter parameters
    const updateFilter = (newFilter) => {
        setFilter(prev => ({ ...prev, ...newFilter }));
    };

    useEffect(() => {
        const fetchAllPosts = async () => {
            try {
                const response = await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/raziskave`);
                if (!response.ok) {
                    throw new Error('Failed to fetch posts');
                }
                let data = await response.json();
                // Sort by the last number in the PostId
                data = data.sort((a, b) => {
                    const getLastNumber = (postId) => parseInt(postId.split('_').pop());

                    return getLastNumber(b.RaziskavaId) - getLastNumber(a.RaziskavaId);
                });
                // Preload images
                data.forEach(post => {
                    const img = new Image();
                    img.src = post.ImageSrc;
                });
                setPosts(data);
            } catch (error) {
                console.error("Error fetching posts:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAllPosts();
    }, []);

    return (
        <RaziskaveContext.Provider value={{ posts, loading, error, filter, updateFilter }}>
            {children}
        </RaziskaveContext.Provider>
    );
};
