import React from "react";
import TitleSection from "../components/hipnoterapija/TitleSection";
import ContentSection from "../components/hipnoterapija/ContentSection";
import { Helmet } from "react-helmet";

function Hipnoterapija(){
    return (
        <div className="postavitev hipno">
            <Helmet>
                <title>Hipnoterapija in Hipnoza</title>
                <meta name="description" content="Odkrijte prednosti hipnoterapije pri Maji Dobravec. Specializirani smo za zdravljenje stresa, anksioznosti, depresije in osebnostno rast." />
                <meta name="keywords" content="Hipnoterapija, Maja Dobravec, Terapija, Stres, Anksioznost, Depresija, Osebnostna rast, Mentalno zdravje, Terapija s hipnozo, Zdravljenje s hipnozo" />
                <meta property="og:title" content="Hipnoterapija in Hipnoza" />
                <meta property="og:description" content="Odkrijte prednosti hipnoterapije pri Maji Dobravec. Specializirani smo za zdravljenje stresa, anksioznosti, depresije in osebnostno rast." />
                <meta property="og:url" content="https://www.hipnoterapijamajad.si/hipnoterapija" />
                <meta property="og:type" content="website" />
            </Helmet>
            <TitleSection />
            <ContentSection />
        </div>
        
    )
}

export default Hipnoterapija;