import React, { useState, useEffect } from 'react';
import './Popup.css'; // Make sure your CSS path is correct
import ContactButton from './ContactButton';

function Popup() {
    const [isVisible, setIsVisible] = useState(false);
    const [allowOpen, setAllowOpen] = useState(true);
    const [isClosing, setIsClosing] = useState(false); // New state to handle closing animation

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300 && allowOpen && !isClosing) setIsVisible(true);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [allowOpen, isClosing]);

    const handleClose = () => {
        setIsClosing(true); // Initiate closing animation
        setTimeout(() => {
            setIsVisible(false); // Finally hide the popup after animation
            setIsClosing(false); // Reset closing state
            setAllowOpen(false);
            setTimeout(() => setAllowOpen(true), 240000); // Re-enable the popup after 3 minutes
        }, 500); // Delay matches the CSS transition duration
    };

    // Adjust the className to trigger animations
    const popupClass = `popup ${isVisible ? 'visible' : ''} ${isClosing ? 'closing' : ''}`;

    return (
        <div className={popupClass}>
            <button className="close-button" onClick={handleClose}>X</button>
            <div className="popup-content">
            <h3>Hipnoterapija MD</h3>
                <p>Za pogovor s strokovnjakom:</p>
                <p>Pokličite - <strong>051 275 721</strong></p>
                <p>Pošljite email - <strong>info@hipnoterapijamajad.si</strong></p>
                <div className='pop-up-btn'>
                    <ContactButton />
                </div>
                
            </div>
        </div>
    );
}

export default Popup;
