
import React from 'react';
import { useLocation } from 'react-router-dom';
import NavbarNorm from './NavbarNorm';
import NavbarCMS from './NavbarCMS';
import NavbarHome from './NavbarHome';

function NavbarSelector() {
  const location = useLocation();
  const isCMSRoute = location.pathname.startsWith('/cms');
  const isAdminRoute = location.pathname.startsWith('/hipnoterapijaAdmin');
  const isHomeRoute = location.pathname === '/';

  if (isCMSRoute) {
    return <NavbarCMS />;
  } else if (isHomeRoute) {
    return <NavbarHome />;
  } else if (isAdminRoute) {
    return;
  } else {
    return <NavbarNorm />;
  }
};


export default NavbarSelector;
