import React from "react";
import PonudbaCard from "../shared/PonudbaCard";
import "./Cenik.css";
import HeadTitle from "../about-me/HeadTitle";



function Cenik() {

  const podatki1 = ["Hipnoterapija (1,5h)", '"Čudovita" Samohipnoza'];
  const podatki2 = ["6x hipnoterapija (30 - 40min)", "Samohipnoza", "Personaliziran - transformacijski posnetek v avdio obliki"];
  const podatki3 = ["3x hipnoterapija (1 - 1.5h)", "Samohipnoza", "Personaliziran - transformacijski posnetek v avdio obliki"];

  const priporocilo250 = "Priporočam ga ljudem po možganski kapi. Priporočljivo je, da se prej preizkusi posamezna seja hipnoterapije ločeno od paketa, ter se tako naredi nadaljnji načrt. Priporočljivih je od 6 - 8 sej, prva traja 1,5h, vse nadaljnje po 30 min. Kar je nad 6 sej, je lahko samo še toliko bolj koristno in doživljenjska zapuščina za vaše možgane in telo. ";
  const priporocilo200 = "Priporočljivo prav za vse, ki vam v ozadju ostajajo čustveno nepredelane zadeve zaradi vsakodnevnega tempa in primanjkovanja časa zase in za odnose na različnih področjih. Posledično ste razdražljivi in ne morete spati. Čustveno čiščenje je en tak paketek, ki je tudi zelo priporočljiv, za ljudi ki se soočajo z odvisnostjo od alkohola, saj je potrebnega kar nekaj dela za razčiščevanje preteklosti. Se soočate s težko boleznijo? Tudi tu tiči vzrok nepredelanih čustev, ki jih dolgo časa gojite v sebi in na tak način se odzovejo določeni organi in pride do bolezni.";



  return (
    <div className="cenik">
      <HeadTitle title={"Cenik storitev"} />
      <div className="circle circle-1"></div>
      <div className="circle circle-2"></div>
      <div className="card-container h-100" >
        {/* First Card */}
        <PonudbaCard
          title="Samostojni termini"
          subtitle="Lahko izbirate med: "
          description={podatki1}
        />
        

        {/* Second Card */}
        <div>
        <PonudbaCard
          title='Paket Ožičeno'
          subtitle="Kaj vam nudi paket?"
          description={podatki2}
          price="250€"
          namen={priporocilo250}
        />
        </div>
        {/* Third Card */}
        <div>
        <PonudbaCard
          title="Paket Čustveno Čiščenje"
          subtitle="Kaj vam nudi paket?"
          description={podatki3}
          price="200€"
          namen={priporocilo200}
        />
        </div>
      </div>
      <p className='obvestilo'><strong>Hipnoza se uporablja kot odpolnitev k vašemu boljšemu počutju in višji motiviranosti in nikakor ne kot nadomestilo h kateremukoli postopku vašega zdravja.</strong></p>
    </div>
  )
}

export default Cenik;