import React from "react";
import imageSrc from "../../../assets/images/hipnoterapijaTab.jpg";
import SpotsGrid from "../../shared/SpotsGrid";


function Hipnoza() {
  return (
    <div className="hipnoza">
      <p>Naš um je sestavljen iz: </p>
      <p><strong>Zavestnega dela</strong> (vsakodnevno premleva, skoraj enake misli, kot dan poprej).<br />To je stanje kjer se zavedamo svojega okolja, misli in občutkov. Zavest je analitična, kar ne pomeni vedno, da je dobra stvar za nas. Zavest vklopi alarm, ko smo v nevarnosti. Da bi se počutili dobro v svoji koži, moramo biti zavestni kakšne misli mislimo skozi dan, in obratno, ko se dobro počutimo se v našo zavest vrnejo pozitivne misli.  </p>
      <p><strong>Podzavestnega dela</strong><br />
        Na tej ravni se začne delo s hipnozo. Se pravi ta del je pod zavestjo in da pridemo do njega, moramo mimo zavesti. V podzavesti se shranjuje naš trajni spomin. Prav vse je arhivirano na tem mestu, tudi tisto kar ste pozabili, je tu shranjeno, in lahko da na dnevni ravni nek spomin ovira vaše dobro počutje, pa se tega niti ne zavedate ( npr. potlačeno čustvo ).
        V tem delu se skrivajo tudi naše navade, čustva, obrambni mehanizem.
      </p>
      <p><strong>Nezavedno</strong><br />
        V tem predelu deluje imunološki sistem, avtomatske funkcije, prejšnja življenja
      </p>
      {/*TUKAJ SLEDI SLIKA*/}
      <div className="hipnoslika">
        <div className="spots1">
          <SpotsGrid rows={11} columns={10} />
        </div>
        <img src={imageSrc} alt="Hipnoterapija" />
      </div>

      {/*TUKAJ SLEDI O HIPNOZI*/}
      <h3 className="dancingfont">O hipnozi (kaj je, kaj ni):</h3>
      <ul>
        <li>Delo s <strong>podzavestjo</strong></li>
        <li>Pripravljenost slediti eni stvari dlje časa, osredotočena koncentracija </li>
        <li>Je najstarejša metoda samozdravljenja</li>
        <li>Je popolnoma <strong>naravno in dovoljeno stanje zavesti</strong>, ki ga doživljamo večkrat dnevno, a se ga niti ne zavedamo ( ko se zjutraj prebujamo, ali ko tonemo v spanec ), nižja valovna frekvenca možganov</li>
        <li><strong>Vsaka hipnoza je samohipnoza</strong>, hipnotizer vas ne more izkoristiti, saj ste še vedno v svojem zavedanju v tem stanju</li>
        <li>Ko doživljate močno čustvo ( npr., ko se na vso moč razburite nad nekom, vas vse drugo ne zanima )</li>
        <li>Dnevno sanjarjenje je hipnoza</li>
        <li>Hipnoza <strong>ni spanje</strong></li>
        <li><strong>Ni čaranje</strong> hipnotizerja</li>
        <li>Je <strong>sodelovanje med klientom in hipnotizerjem</strong> (vi vozite avto, jaz navigiram) in je popolnoma <strong>varna</strong></li>
        <li>Socialna hipnoza je zelo močna, pa niti ne razmišljamo o tem kako nam lahko škodi. Pomembno je, da se jo zavedamo. Dogaja se namensko ali pa nenamensko v interakciji: starši – otroci, zdravnik – pacient, učitelj – učenec, duhovnik – vernik, televizija – gledalec... <strong>Pomislite malo iz te perspektive.</strong></li>
      </ul>
      {/*TUKAJ SLEDI POVEZAVA*/}
      <dir className="ozadje">

        <div className="spots2">
          <SpotsGrid rows={11} columns={10} />
        </div>
        <div className="spots3">
          <SpotsGrid rows={11} columns={10} />
        </div>
        <div className="zin">
          <h4 className="dancingfont"><strong>Če vse skupaj povežem.</strong></h4>
          <p>Hipnoza se zgodi s pomočjo hipnotizerja, na način, da sledite njegovim sugestijam, ki vas popeljejo do telesne in umske sprostitve. </p>
          <h4 className="dancingfont"><strong>Kaj se zgodi?</strong></h4>
          <p>Vaš analitični um se umiri in vse <strong>nepotrebne misli se umaknejo</strong>, zatorej je vaš fokus usmerjen v tisto eno stvar katero bi radi razrešili. Npr. delo na odpravi strahu. Ker ste prisotni v trenutku, ste usmerjeni s svojimi mislimi 100% na to eno stvar, s pomočjo mojega vodenja. <strong>Sam občutek ni podoben ne spanju, niti nezavestnemu stanju.</strong></p>
          <p>Podoben je občutku, kot takrat, ko meditirate, torej v vrzeli med dvema mislima. <strong>Kjer čas in prostor nista prisotna.</strong> Zato ena seja, ki traja uro in več, daje občutek, kot da je minilo šele 5 min.</p>
          <h4 className="dancingfont"><strong>Kaj je hipnoterapija?</strong></h4>
          <p><strong>Hipnoterapija</strong> je metoda kjer se uporablja hipnoza.</p>
          <p><strong>Hipnoza</strong> je lahko odlično orodje za samopomoč. Je pa lahko neuporabno, če ne veste kako si z njim pomagati. <strong>Zato so tu hipnoterapevti, ki so razvili svoje veščine na način, da vas usmerjajo in vam pomagajo priti do rešitev.</strong> </p>
        </div>

      </dir>


    </div>
  );
}

export default Hipnoza;