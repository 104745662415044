import React from "react";
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import imageSrc1 from '../../assets/images/logo-bel.png';
import './NavbarNorm.css';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



function NavbarHome(){

    return(
    <Navbar expand="lg" data-bs-theme="dark" className=" navbar-fixed-top bg-body-tertiary home">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="logo"><img className="logo" src={imageSrc1} alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="no-border">
        <FontAwesomeIcon icon={faBars} style={{ color: 'white' }}/>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end navbar-collapsed-content">
          <Nav >
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/omeni">O meni</Nav.Link>
            <Nav.Link as={Link} to="/hipnoterapija">Hipnoterapija</Nav.Link>
            <Nav.Link as={Link} to="/blog">Blog</Nav.Link>
            <Nav.Link as={Link} to="/raziskave">Raziskave</Nav.Link>
            <Nav.Link as={Link} to="/ponudba">Cenik</Nav.Link>
            <Nav.Link as={Link} to="/kontakt">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default NavbarHome;