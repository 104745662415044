import React, { useEffect } from "react";
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Hipnoza from "./podstrani/Hipnoza";
import Blokade from "./podstrani/Blokade";
import Samohipnoza from "./podstrani/Samohipnoza";
import Skrb from "./podstrani/Skrb";
import Kap from "./podstrani/Kap";
import Odpuscanje from "./podstrani/Odpuscanje";
import Alkoholizem from "./podstrani/Alkoholizem";
import { useLocation } from 'react-router-dom';
import useScrollToTop from '../../hooks/useScrollToTop.js';


function ContentSection(){

    const [activeTab, setActiveTab] = useState('hipnoza');
    const location = useLocation();
    const handleTabSelect = (eventKey) => {
      setActiveTab(eventKey);
    };

    
  useEffect(() => {
    // Check if state exists and has the activeTab property
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location]);
  useScrollToTop();
    return (
    <div className="tabs-container">
    <Tabs
      id="justify-tab-example"
      className=""
      justify
      activeKey={activeTab}
      onSelect={handleTabSelect}
    >
      <Tab eventKey="hipnoza" title="Hipnoza in Hipnoterapija">
        <Hipnoza />
      </Tab>
      <Tab eventKey="samohipnoza" title="Čudovita Samohipnoza">
        <Samohipnoza />
      </Tab>
      <Tab eventKey="blokade" title="Čustvene blokade">
        <Blokade />
      </Tab>
      <Tab eventKey="skrb" title="Pretirana skrb">
        <Skrb />
      </Tab>
      <Tab eventKey="kap" title="Možganska kap">
        <Kap />
      </Tab>
      <Tab eventKey="odpuscanje" title="Odpuščanje">
        <Odpuscanje />
      </Tab>
      <Tab eventKey="alkoholizem" title="Alkoholizem">
        <Alkoholizem />
      </Tab>

      {/* Add other tab content components similarly */}
    </Tabs>
    </div>
  );
}

export default ContentSection;