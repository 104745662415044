import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./CmsBlogDetails.css";

function CmsRaziskaveDetails() {

  const [raziskava, setRaziskave] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false); // Added for toggle edit mode
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRaziskaveDetails = async () => {
      try {
        const response = await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/getRaziskavaById?postId=${postId}`);
        if (!response.ok) {
          throw new Error('Raziskava not found');
        }
        const data = await response.json();
        console.log(data); // Handle the response data
        setRaziskave(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRaziskaveDetails();

  }, [postId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRaziskave({ ...raziskava, [name]: value });
  };

  const handleSectionChange = (sectionIndex, key, value) => {
    const updatedContent = raziskava.Content.map((section, index) => {
      if (index === sectionIndex) {
        if (key === 'paragraphs') {
          return { ...section, paragraphs: [value] };
        }
        return { ...section, [key]: value };
      }
      return section;
    });
    setRaziskave({ ...raziskava, Content: updatedContent });
  };

  const handleAddSection = () => {
    const newSection = { subtitle: '', paragraphs: [''] };
    setRaziskave({ ...raziskava, Content: [...raziskava.Content, newSection] });
  };

  const handleRemoveSection = () => {
    if (raziskava.Content.length > 1) {
      const updatedContent = raziskava.Content.slice(0, -1);
      setRaziskave({ ...raziskava, Content: updatedContent });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Store the selected file
  };

  const uploadImage = async (file) => {
    // Fetch presigned URL from your backend
    const presignedUrlResponse = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/upload-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName: file.name, fileType: file.type }),
    });
    const { url } = await presignedUrlResponse.json();

    // Upload the file to S3
    const uploadResponse = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (uploadResponse.ok) {
      return url.split('?')[0]; // URL without query parameters
    } else {
      throw new Error('Failed to upload image');
    }
  };



    const validateImageFile = (file) => {
        return !file || (file && file.type.startsWith('image/'));
    };


  const handleSave = async () => {
    setIsLoading(true);

            // Reset validation errors
    setValidationErrors({});



        // Validation
        let errors = {};


// Validate the image file if one has been selected
if (!validateImageFile(raziskava.imageFile)) {
    errors.imageFile = 'Please upload a valid image file.';
}

if (Object.keys(errors).length > 0) {
    setValidationErrors(errors);
    setIsLoading(false);
    return; // Stop submission if there are validation errors
}


    if (selectedFile) {
      // Assuming you have a function to handle the file upload and it returns the URL
      try {
        const imageUrl = await uploadImage(selectedFile);
        //setRaziskave({ ...raziskava, ImageSrc: imageUrl }); // Update the blog object with the new image URL
        raziskava.ImageSrc = imageUrl;
      } catch (error) {
        console.error("Error uploading image:", error);
        alert('Error uploading image. Please try again.');
        return;
      }
    }


    try {
       // Start loading
       console.log(raziskava);
      // Replace this URL with your actual endpoint
      const response = await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/update-raziskava-post/${postId}`, {
        method: 'PUT',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "OPTIONS,PUT",
          "Access-Control-Allow-Headers": "Content-Type"
        },
        body: JSON.stringify(raziskava),
      });

      if (!response.ok) {
        throw new Error('Failed to save raziskava post');
      }

      alert('Raziskava updated successfully');
      setIsEditing(false); // Exit editing mode
      // Optionally, fetch the blog details again to ensure the UI is updated
    } catch (error) {
      console.error("Error saving raziskava:", error);
      alert('Error saving raziskava. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };




  const handleDelete = async () => {
    // API call to delete the blog post
    setIsLoading(true);
    try {
      await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/delete-raziskava-post/${postId}`, { method: 'DELETE' });
      alert('Raziskava deleted successfully');
      navigate('/cms/raziskave-list'); // Navigate back to the blog list
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting raziskave:", error);
    }
  };












  if (loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div>;






  return (

    <div className="cms-blog-details-container">
      {loading ? (
        <div className="loading-indicator">Loading...</div>
      ) : (
        <>
          {isEditing ? (
            <div className="edit-form">
              <div className="form-group">
                <label htmlFor="Title" className="form-label">Naslov:</label>
                <input type="text" id="Title" name="Title" className="form-input" value={raziskava.Title} onChange={handleInputChange} />
              </div>

              <div className="form-group">
                    <label className="form-label">Kategorija:</label>
                    <input
                        type="text"
                        className="form-input"
                        name="category"
                        value={raziskava.Category}
                        onChange={handleInputChange}
                    />
              </div>
              <div className="form-group">
                    <label className="form-label">Čas branja (minute):</label>
                    <input 
                        type="number" 
                        className="form-input"
                        name="ReadingTime" 
                        value={raziskava.ReadingTime} 
                        onChange={handleInputChange}
                        min="1"
                    />
              </div>
              <div className="form-group">
                <label htmlFor="ImageSrc" className="form-label">Naloži sliko:</label>
                <input type="file" id="imageFile" name="imageFile" onChange={handleFileChange} className="form-input" />
                {validationErrors.imageFile && <div className="error">{validationErrors.imageFile}</div>}
              </div>

              {raziskava.Content.map((section, index) => (
                <div key={index} className="section-edit">
                  <div className="form-group">
                    <label htmlFor={`subtitle-${index}`} className="form-label">Podnalsov:</label>
                    <input type="text" id={`subtitle-${index}`} className="form-input" value={section.subtitle} onChange={(e) => handleSectionChange(index, 'subtitle', e.target.value)} />
                  </div>

                  {section.paragraphs.map((paragraph, pIndex) => (
                    <div key={pIndex} className="form-group">
                      <label htmlFor={`paragraph-${index}-${pIndex}`} className="form-label">Odstavek {pIndex + 1}</label>
                      <textarea id={`paragraph-${index}-${pIndex}`} className="form-textarea" onChange={(e) => handleSectionChange(index, 'paragraphs', e.target.value)} defaultValue={paragraph}></textarea>
                    </div>
                  ))}
                </div>
              ))}
              <div className="section-buttons">

                <button onClick={handleAddSection} className="btn btn-add mr-2">Dodaj odstavek</button>
                <button onClick={handleRemoveSection} className="btn btn-remove mr-2">Odstrani odstavek</button>
                <button onClick={() => setIsEditing(!isEditing)} className="btn btn-cancel mr-2">Prekliči</button>
                <button onClick={handleSave} className="btn btn-save"> {isLoading ? "Nalaganje..." : "Shrani"}</button>

              </div>
            </div>
          ) : (
            <div className="blog-details-view">
              <h2 className="blog-title">{raziskava.Title}</h2>
              <p className="blog-date">{raziskava.CreationDate} {raziskava.ReadingTime} min</p>
              {raziskava.ImageSrc && <img src={raziskava.ImageSrc} alt={raziskava.Title} className="blog-image" />}
              {raziskava.Content.map((section, index) => (
                <div key={index} className="blog-section">
                  <h3 className="section-subtitle">{section.subtitle}</h3>
                  {section.paragraphs.map((paragraph, pIndex) => (
                    <p key={pIndex} className="section-paragraph">
                      {paragraph.split('\n').map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          {lineIndex < paragraph.split('\n').length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  ))}
                </div>
              ))}
              <div className="action-buttons">
                <button onClick={() => setIsEditing(!isEditing)} className="btn btn-update mr-2">Posodobi</button>
                <button onClick={handleDelete} className="btn btn-delete">{isLoading ? "Brisanje..." : "Izbriši"}</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CmsRaziskaveDetails;
