import React from "react";
import imageFull from "../../../assets/images/alkofull.png";
import imageCrop from "../../../assets/images/alkocrop.png";
import SpotsGrid from "../../shared/SpotsGrid";


function Alkoholizem() {
    return (
        <div className="alkoholizem">
            <p><strong>Še preden začnete brati, nadaljujte samo v primeru, da ste resnično vi sami odločeni, da želite prenehati s pitjem alkohola, drugače ne jaz ne vi ne bova rešila nič. Želja po prenehanju pitja alkohola  mora biti močnejša od kozarčka alkohola!</strong></p>
            <p>Alkoholizem bližnjega me spremlja že od malih nog. Vem kako je, ko odvisnost enega samega človeka vpliva na celotno <strong>družino</strong>. Kako se počutite vi, ki ste zabredli v odvisnost, pa ne morem vedeti. Verjamem, da vam ni lahko in se vsak dan vstanete z <strong>bolečino</strong>.
                <br />Zato je tu <strong>hipnoza in hipnoterapija</strong>, s katero si lahko pomagate v veliki meri. Jaz vam stojim ob strani in vas <strong>usmerjam</strong>. Potrebnega je <strong>veliko dela</strong>, zatorej ne pričakujte, da se bodo zadeve razrešile v eni sami seji. Delo se začne s <strong>čiščenjem emocij</strong> vse za nazaj in iskanjem <strong>vzroka</strong> za odvisnost, <strong>ozaveščanje</strong> tega, odpuščanje drugim, delo na <strong>motivaciji, samozavesti, samodisciplini, ter transformacija v prihodnost</strong>, tako, kot si jo želite da je.
            </p>
            <h4 className="dancingfont mb-5"><strong>Veselo na delo, sreča je v vaših rokah!</strong></h4>

            <div className="alkoslika">
                <div className="spots4 addsp">
                    <SpotsGrid rows={11} columns={10} />
                </div>
                <div className="spots5 addsp">
                    <SpotsGrid rows={11} columns={10} />
                </div>
                <img className="alkofull" src={imageFull} alt="Alkoholizem Slika" />
                <img className="alkofull backshadow" src={imageCrop} alt="Alkoholizem Slika" />
            </div>
        </div>
    )
}

export default Alkoholizem;