import React from 'react';

import './Raziskava.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';



function RaziskaveItem({ title, content, date, raziskavaId, imageSrc, read, category }) {
  const navigate = useNavigate();

  // Ensure that content is an array and has at least one item with paragraphs
  const firstParagraph = content && content[0].paragraphs ? content[0].paragraphs : '';

  // Get the first 100 characters of the first paragraph
  const previewText = firstParagraph[0].length > 100 ? firstParagraph[0].slice(0, 100) + '...' : firstParagraph[0];

  return (
    <div className="blog-item" onClick={() => navigate(`/raziskave/${raziskavaId}`)}>
      <div className="image">
        <img src={imageSrc} alt="Blog" />
      </div>
      <div className="details">
        <div className="info">
          <p><span className='category'>{category}</span> <FontAwesomeIcon icon={faCircle} className="small-circle" /> {date} <FontAwesomeIcon icon={faCircle} className="small-circle" /> <FontAwesomeIcon icon={faHourglassHalf} className='hourglass' /> {read} min</p>
        </div>
        <div className="titleblog">
          <h3>{title}</h3>
          <p>{previewText}</p>
        </div>
      </div>
    </div>
  );
}

export default RaziskaveItem;
