import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import './NewsletterForm.css';

function NewsletterForm() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(''); // State to store the email validation error message
  const [successMessage, setSuccessMessage] = useState(''); 

  // Simple email validation function
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
      );
  };


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Clear previous messages
    setEmailError('');
    setSuccessMessage('');
        // Validate the email address
        if (!validateEmail(email)) {
          setEmailError('Please enter a valid email address.');
          return; // Stop the submission if the email is invalid
        }

    // Replace 'https://your-api-gateway-url/submit-blog' with your actual API Gateway endpoint
   // const apiEndpoint = 'http://127.0.0.1:3000/submit-email';https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com
   const apiEndpoint = 'https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/submit-email';
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }), // Send the email as JSON
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle success response
      console.log('Successfully submitted:', await response.json());
      setSuccessMessage('Hvala za prijavo na E-Novice!');
      setEmail('');
    } catch (error) {
      console.error('Error submitting the form:', error);
      setEmailError('Napaka pri prijavi!');
    }
  };

  return (
    <Form inline onSubmit={handleSubmit}>
      <Form.Label className='tekst'><strong>Prijavite se na e-novice:</strong></Form.Label>
      <div className="d-inline-flex">
        <Form.Control 
          type="email" 
          placeholder="name@example.com" 
          className="newsletter-field" 
          value={email}
          onChange={e => setEmail(e.target.value)} // Update email state on change
          isInvalid={!!emailError}
        />
        <Button type="submit" className="newsletter-field newsletter-button">
          <FontAwesomeIcon icon={faArrowRightLong} className='arrow-icon' />
        </Button>      
      </div>
      {emailError && <div className="text-danger">{emailError}</div>}
        {successMessage && <div className="text-white">{successMessage}</div>}
    </Form>
  );
}

export default NewsletterForm;
