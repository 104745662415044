import React from 'react';
import './LandingSection.css'; // Import CSS file for styling
import NewsletterForm from '../shared/NewsletterForm';

function LandingSection() {
    return (
        <div className="landing-section">
            <div className="landing-text">
                <h1 className="heading  white-heading">HIPNOTERAPIJA <br /> MAJA DOBRAVEC</h1>
                <h1 className="heading  orange-heading">Hipnoterapija <br /> <span className='ime-ozadje'>Maja<br /> Dobravec</span></h1>
                <p className='landing-description'>Od psihične bolečine do svobodnega občutka! Moj cilj je vaša notranja moč!</p>

                <NewsletterForm />
            </div>
        </div>
    );
}

export default LandingSection;