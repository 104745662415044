import React, { useState } from 'react';
import "./BlogForm.css"// Consider renaming this CSS file if it's solely used for Raziskave

function RaziskaveForm() {
    const [raziskave, setRaziskave] = useState({
        title: '',
        creationDate: '',
        imageSrc: '',
        content: [{ subtitle: '', paragraphs: [''] }],
        category: '',
        readingTime: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRaziskave({ ...raziskave, [name]: value });
    };

    const handleContentChange = (sectionIndex, e) => {
        const { name, value } = e.target;
        const updatedContent = [...raziskave.content];

        if (name === 'paragraphs') {
            updatedContent[sectionIndex][name] = [value];
        } else {
            updatedContent[sectionIndex][name] = value;
        }

        setRaziskave({ ...raziskave, content: updatedContent });
    };

    const addContentSection = () => {
        setRaziskave({
            ...raziskave,
            content: [...raziskave.content, { subtitle: '', paragraphs: [''] }]
        });
    };

    const removeLastContentSection = () => {
        if (raziskave.content.length > 1) {
            const updatedContent = raziskave.content.slice(0, -1);
            setRaziskave({ ...raziskave, content: updatedContent });
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setRaziskave({ ...raziskave, imageFile: file });
    };

    const validateImageFile = (file) => {
        return !file || (file && file.type.startsWith('image/'));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        setValidationErrors({});
        let errors = {};

        if (!validateImageFile(raziskave.imageFile)) {
            errors.imageFile = 'Please upload a valid image file.';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            setIsSubmitting(false);
            return;
        }

        if (raziskave.imageFile) {
            try {
                const presignedUrlResponse = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/upload-image', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileName: raziskave.imageFile.name, fileType: raziskave.imageFile.type }),
                });
                const { url } = await presignedUrlResponse.json();

                const uploadResponse = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': raziskave.imageFile.type,
                    },
                    body: raziskave.imageFile,
                });

                if (uploadResponse.ok) {
                    const imageUrl = url.split('?')[0];
                    raziskave.imageSrc = imageUrl;
                } else {
                    throw new Error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                setIsSubmitting(false);
                return;
            }
        }

        try {
            // Create a new Date object representing the current date
            const currentDate = new Date();

            // Format the date string in the format 'Month day, year', e.g. 'Nov 30, 2023'
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthIndex = currentDate.getMonth(); // Month index starts from 0
            const year = currentDate.getFullYear();
            const day = String(currentDate.getDate()).padStart(2, '0');

            const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;

            // Add the formatted date to the blog object
            raziskave.creationDate = formattedDate;
            await submitRaziskave(raziskave);
            setRaziskave({
                title: '',
                creationDate: '',
                imageSrc: '',
                content: [{ subtitle: '', paragraphs: [''] }],
                category: '',
                readingTime: '',
            });
            alert('Raziskava submitted successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting raziskava');
        }
        setIsSubmitting(false);
    };

    const submitRaziskave = async (raziskaveData) => {

        try {
            // Note: Replace 'http://127.0.0.1:3000/submit-raziskave' with your actual endpoint URL
            const response = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/submit-raziskave', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(raziskaveData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data); // Handle the response data accordingly
            alert('Raziskava submitted successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting raziskava');
        }
    };

    return (
        <div className='blog-form'>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label>Naslov:</label>
                    <input type="text" name="title" value={raziskave.title} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Kategorija:</label>
                    <input
                        type="text"
                        name="category"
                        value={raziskave.category}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label>Čas branja (minute):</label>
                    <input
                        type="number"
                        name="readingTime"
                        value={raziskave.readingTime}
                        onChange={handleInputChange}
                        min="1"
                    />
                </div>
                <div className="form-group">
                    <label>Naloži sliko:</label>
                    <input type="file" onChange={handleFileChange} />
                    {validationErrors.imageFile && <div className="error">{validationErrors.imageFile}</div>}
                </div>
                {raziskave.content.map((section, index) => (
                    <div key={index} className="content-section">
                        <div className="form-group">
                            <label>Podnaslov:</label>
                            <input type="text" name="subtitle" value={section.subtitle} onChange={(e) => handleContentChange(index, e)} />
                        </div>
                        <div className="form-group">
                            <label>Odstavek:</label>
                            <textarea name="paragraphs" value={section.paragraphs[0]} onChange={(e) => handleContentChange(index, e)} />
                        </div>
                    </div>
                ))}
                <div className="form-actions">
                    <button type="button" onClick={addContentSection}>Dodaj odstavek</button>
                    <button type="button" onClick={removeLastContentSection}>Odstrani odstavek</button>
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Nalaganje...' : 'Naloži Raziskavo'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default RaziskaveForm;
