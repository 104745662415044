import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './PonudbaCard.css'; // Import your CSS file
import ContactButton from './ContactButton';

function PonudbaCard({ title, subtitle, description, price, namen}) {
  
  // Convert description into an array of <li> items
  const pricesChoice = ["50€","60€","60€"]
    // Convert description into an array of <li> items
    const descriptionItems = description.map((item, index) => (
      <div className="description-item" key={index}>
        <p>{price  && <FontAwesomeIcon icon={faCheck} style={{color: "#dcab70",}} />}  {item}</p>
        {!price && <p className="prie-choice dancingfont"><strong>{pricesChoice[index]}</strong></p>}
      </div>
    ));

    const [showModal, setShowModal] = useState(false);

const handleShow = () => setShowModal(true);
const handleClose = () => setShowModal(false);
  
  return (
    <Card className="ponudba-card" >
      <Card.Body>
        <Card.Title className='cardtitle'><strong>{title}</strong></Card.Title>
        <Card.Subtitle className="mb-4 vsebina cardsubtitle"><strong>{subtitle}</strong></Card.Subtitle>
        <dir className='vsebina'>
            {descriptionItems} 
        </dir>
        <div className="card-footer">
        
          <div className="price dancingfont"><strong>{price}</strong></div>
          {namen && <div><p className='linknamen mb-0' onClick={handleShow}> Komu je namenjeno?</p></div>}
          <ContactButton />
        </div>
      </Card.Body>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Komu je namenjeno</Modal.Title>
        </Modal.Header>
        <Modal.Body>{namen}</Modal.Body>
        <Modal.Footer>
          <Button className='button' variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
}

export default PonudbaCard;
