import React from "react";
import imageDesno from "../../../assets/images/kapdesno.png";
import imageLevo from "../../../assets/images/kaplevo.png";
import "../Hipnoterapija.css";


function Kap() {

    return (
        <div className="kap">
            <p>Imam veliko <strong>poslanstvo</strong> stati ob strani ljudem, preživelim po možganski kapi in njihovim svojcem. Hipnoza in hipnoterapija sta ena stvar s katero si lahko pomagate iz obeh strani, tako preživeli kot svojci.<br /> Druga stvar pa je, da boste na tej strani lahko prebirali posredovane <strong>informacije</strong> povezane z možgansko kapjo, od raziskav na tem področju, do tega kaj je dobro da vedo svojci, do tega kje si lahko preberete še kaj več o tej temi, predvsem sem pa tukaj za <strong>motivacijo</strong>, da ne obstanete na mestu.</p>
            <p>Posameznik se lahko v enem trenutku počuti dobro, nato pa ga nenadoma zadane možganska kap.
                Možganska kap je <strong>glavni vzrok invalidnosti</strong> pri odraslih, ki pogosto vodi v <strong>depresivno stanje in osebnostne spremembe.</strong>
            </p>
            <div className="ozadje">
                <p><strong>Ste en-a izmed teh oseb, ki vas je zadela možganska kap in raziskujete kako si lahko še dodatno pomagate pri vašem okrevanju, tako iz fiziološkega kot psihološkega vidika?</strong></p>
                <p className="mb-2">Preberite nekaj nasvetov -> <a href="https://hipnoterapija-md-bucket.s3.eu-central-1.amazonaws.com/MINI+NASVETI+mo%C5%BEganska+kap.pdf" target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
            Naloži dokument
          </a></p>
            </div>
            <h4 className="dancingfont mt-5"><strong>S pomočjo hipnoze, klienti lahko:</strong></h4>
            <ul>
                <li>Ponovno pridobijo občutek <strong>ravnotežja</strong></li>
                <li>Zmanjšajo <strong>otrplost</strong></li>
                <li>Pridobijo nadzor, funkcijo in manever prizadete strani</li>
                <li>Nadzor <strong>impulzivnega vedenja</strong></li>
                <li>Izboljšajo proces fizioterapij</li>
                <li>Povečajo <strong>apetit</strong> po zdravi hrani</li>
                <li>Povečajo <strong>motivacijo</strong> za izboljšavo svojega stanja</li>
                <li>Zmanjšajo <strong>tesnobo in žalost</strong></li>
            </ul>
            <h4 className="dancingfont"><strong>Rezultat:</strong></h4>
            <p>Je stvar posameznika. Klienti s pomočjo hipnoze lahko dosežejo veliko <strong>boljše rezultate</strong> vzporedno z ostalo rehabilitacijo, saj jim hipnoza pomaga, da se sprostijo in osredotočijo na <strong>okrevanje</strong>, ter izboljšajo svoje <strong>kognitivne sposobnosti</strong>, kot sta koncentracija in spomin.</p>
            <p><strong>Naj omenim, da je hipnoza primerna za kliente, ki lahko sledijo navodilom. Sami presodite ali bi bila hipnoza primerna za vas, ali s pomočjo svojcev oz. se posvetujte s svojim zdravnikom!
                <br />Hipnoza se uporablja kot dopolnitev k vašemu boljšemu počutju in višji motiviranosti in nikakor ne kot nadomestilo h kateremukoli postopku vašega zdravljenja!
            </strong></p>
            <p>Tudi če je minilo 10 let od možganske kapi, lahko lajšate težave s pomošjo hipnoze oz. pridete do boljšega stanja telesa uma in duha.</p>
            <div className="ozadje mb-5">
                <p className="mb-0">Več o tem, na kakšen način lahko hipnoza pomaga po možganski kapi, si lahko preberete pod rubriko »RAZISKAVE«.</p>
                {/*BUTTON ZA RAZISKAVE */}
            </div>
            <div className="topbg">
                <h4 className="dancingfont"><strong>Jaz in kako me povezuje možganska kap z ljudmi:</strong></h4>
                <p>Začela bom s svojo izpovedjo in izkušnjo kot hči mame, ki jo je 1. zadela možganska kap pri njenih 34 letih. Jaz sem bila stara 6 let in moja sestra 9 let. Tega obdobja se bolj malo spomnim, saj sem že prej, ali pa ravno s to izkušnjo, postala mojstrica tlačenja spominov in občutkov v predalčke, v podzavesti. Bila sem v stiski in zelo navezana na svojo mamo vse svoje otroštvo. Skozi svoje življenje je doživela še 7 možganskih kapi. Bilo je tako, da se je vsakič sproti nekako pobrala, ni bila gibalno prizadeta, niti govor ni bil prizadet, oz. zelo malo, toliko da se ji je zapletal jezik. Je bilo pa z vsako kapjo vedno slabše njeno ravnotežje, ter njene kognitivne sposobnosti ( spomin, koncentracija, fokus, zmožnost odločanja ). Pred njeno zadnjo kapjo je že komaj hodila, ni bilo moči, predvidevam, da med drugim tudi ni prihajalo zadostnega kisika do možganov. Imela je zožene vratne žile, zaradi česar je bila tudi na operaciji.
                    <br />Pri svojih 69 letih je umrla zaradi posledic možganske kapi. Po zadnji kapi je živela še kakšno leto, izgubila je govor, prizadeta je bila desna stran telesa, ni mogla slediti navodilom, motnje požiranja.
                    <br />Moja mami je obupala že prej nad življenjem, zato se njeno stanje tudi ni izboljševalo.
                </p>
                <h4 className="dancingfont"><strong>Kaj želim izpostaviti!?</strong></h4>
                <p>Ogromno je možganskih kapi in ogromno trpečih ljudi zaradi tega. Bolj  kot se bodo ljudje povezovali med seboj s podobnimi izkušnjami, bolje se bodo znašli v situacijah povezanih s tem stanjem po možganski kapi.</p>
                <p>Veliko breme je na svojcih in tudi veliko je odvisno od svojcev kako dobro in intenzivno bo preživeli po možganski kapi okreval.
                    <br />Ko premlevam za nazaj situacijo moje mamice in nas, ki smo živeli z njo, nihče kaj dosti ni vedel za kaj se gre in kako se dejansko mami počuti v svoji glavi in kaj lahko naredimo mi za njo. Oz. jaz kot otrok, sem se zadevi le prilagajala in sledila navodilom. So formalne zadeve, ki jih izve tako pacient kot njegovi svojci. Pacient gre iz bolnice na rehabilitacijo, nato glede na okoliščine še na post rehabilitacijo, če finance dopuščajo, se nadaljuje doma še kakšna fizioterapija, pa še to samo nekajkrat na teden.
                </p>
                <img className="kapslikaozadjedesno" src={imageDesno} alt="ozadje" />
            </div>
            <p className="mb-4 mt-4"><strong>Važno je za vse nas, da vemo, da so možgani preživelih po možganski kapi poškodovani in ne delujejo več enako kot prej!</strong></p>
            <div className="topbg">
                <p>Če bi, potem bi po možganski kapi takoj lahko začeli premikati svoje ude, govoriti brez zatikanja, požirati normalno kot prej….
                    <br />Po možganski kapi preživelega so tukaj zunanje razlike, ki so nam očitne vsem, to je nezmožnost gibanja ene strani telesa. Tako preživelemu kot njegovim bližnjim in prijateljem se zdi kot, da je to edini vrh ledene gore njihove preobrazbe na kateri bodo delali, vložili ves trud za okrevanje. Pa vendar je še veliko več v ozadju. Nam, zunanjim opazovalcem še zdaleč niso očitne stopnje utrujenosti in izčrpanosti, bolečine in glavobolov, ter sprememb čustvenega stanja posameznika. Ta se lahko pojavijo v obliki tesnobe, frustracije, jeze, depresije in težav pri obvladovanju čustev.
                    <br />Zatorej je dobro, da se zavedamo, da je potrebna drugačna komunikacija z ljudmi po možganski kapi, predvsem bolj potrpežljiva in z višjo mero inteligence. Pozabite na obsojanje, zakaj je to tako in ne tako, kako ne zmoreš tega in onega, kako se tega ne spomniš, itn.. <strong>Veliko dela je potrebnega, ko preživeli pride nazaj v domače okolje</strong>, ali pa se vse skupaj prepusti pasivnosti in še večji čustveni bolečini.
                </p>
                <h4 className="dancingfont sredina"><strong>Torej, glavo pokonci, zavihati rokave in veselo na vsakodnevno delo in v lastni režiji delati vaje za izboljšanje stanja, na vseh potrebnih področjih!</strong></h4>
                <p>Pomagalo vam bo, da ste radovedni in raziskujete, kaj lahko še naredite sami doma za okrevanje po kapi. V tej dobi hitre dostopnosti informacijam je toliko lažje, da pridete do kakšnih dodatnih znanj glede vaše situacije.
                    <br />Veliko je ljudi, ki jih je doletela možganska kap, zatorej je nesmiselno, da čakate, da vas bo kdo rešil, ali da vam pade iz neba še kakšna rehabilitacija. Na vas je, kako kvalitetno boste okrevali. Četudi nimate denarja za fizioterapijo ali kakšno drugo terapijo, <strong>se veliko da narediti doma</strong>, seveda s pomočjo bližnjih. Vem pa tudi to, da vas je veliko prepuščenih slabim odnosom in okoliščinam, kjer je posledično nejevolja in okrnjeno delo na sebi in izboljšanju stanja.
                    <br />Samo ne obupajte, vedno je rešitev in nekdo, ki lahko pomaga.
                </p>
                <h4 className="dancingfont sredina"><strong>Tukaj smo en za drugega!</strong></h4>
                <img className="kapslikaozadjelevo" src={imageLevo} alt="Ozadje" />
            </div>
            <p>Delala sem tudi na <strong>medicinskem oddelku</strong>, kot maserka, kjer sem se srečevala z ljudmi, ki so prišli na rehabilitacijo po možganski kapi in niti ena možganska kap ni enaka drugi, niti en človek ni bil enak drugemu.
                <br />In ravno tak pristop imam jaz do posameznika. Ga začutim in naredim personaliziran načrt dela.
                <br /><strong>Vaš cilj postane moje poslanstvo, da ga skupaj dosežemo!</strong>
            </p>
        </div>
    )
}

export default Kap;