import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DejavnostCardList.css';



function Card({ image, title, description, tab }) {

  let navigate = useNavigate();

  const navigateToHipnoterapija = () => {
    navigate("/hipnoterapija", { state:{ activeTab: tab }}); // Replace 'desiredTabKey' with the actual key
  };

  return (
    <div className="card" onClick={navigateToHipnoterapija}>
    <div className="image-container">
    <div className="image-overlay"><p className='overlay-text'>PREBERI VEČ</p></div> {/* Overlay */}
    <img src={image} alt="Alt text" className="card-img"/>
  </div>
  <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
}

export default Card;
