import React from 'react';
import './FooterNorm.css';



function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <footer className="footer">
      <div className="footer-left">
        <img className='logo-foot mb-3' src="https://hipnoterapija-md-bucket.s3.eu-central-1.amazonaws.com/logo-bel.png" alt="Logo" />
        <p>Vsake stvari se lotim poglobljeno in profesionalno, tako na zasebnem,
          kot na poslovnem področju.<br />
          Temu sledi, da je vsaka posamezna hipnoterapija maksimalno učinkovita.<br />
          Vse niti so v vaših rokah, jaz pa vas vodim skozi proces!</p>
      </div>
      <div className="footer-right">
        <div className="social-media-links">
        <h5>Maja Dobravec</h5>
        <p className='mb-0'>Dolenjske Toplice, Novo mesto</p>
        <p className='mb-0'>Tel. 051 275 721</p>
        <p>Email. info@hipnoterapijamajad.si</p>
        </div>

      </div>
      <div className="company-info">
        <p className='mt-3'>&copy; {currentYear} Hipnoterapija Maja Dobravec</p>
      </div>
    </footer>
  );
}

export default Footer;