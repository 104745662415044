import React from "react";
import imageLevo from "../../../assets/images/skrblevo.jpg";
import imageDesno from "../../../assets/images/skrbdesno.jpg";
import imageSpodaj from "../../../assets/images/skrbspodaj.jpg";


function Skrb() {
  return (
    <div className="skrb">
      <h4 className="dancingfont"><strong>Ste konstantno zaskrbljeni?</strong></h4>
      <ul>
        <li>Imate občutek, da morate imeti venomer nadzor nad vsemi stvarmi, nad okolico, nad družinskimi člani, da drugače zadeve enostavno ne bodo stekle?</li>
        <li>Ste v težkem položaju in zato nemirni ter v stresu?</li>
        <li>Ste v vsakodnevni naglici in zato v skrbeh, da ne boste zmogli vsega postoriti?</li>
      </ul>
      <p className="mb-5 mt-5"><strong>Ali občutite, da je ta skrb pretirana in vam prav nič ne koristi in je celo destruktivna?</strong></p>
      <h4 className="dancingfont"><strong>Delo v stanju hipnoze:</strong></h4>
      <p>S pomočjo hipnoze lahko ugotovite <strong>vzrok pretirane skrbi</strong>, jo ozavestite in nevtralizirate. Temu sledi transformacija v <strong>lepšo prihodnost</strong> kjer so stvari takšne kot si želite, da so.</p>
      <h4 className="dancingfont"><strong>Rezultat:</strong></h4>
      <p>Negativne misli zamenjajo pozitivne.<br />
        Zaupanje vase in v druge. <br />
        Razbremenjenost in umirjenost!
      </p>

      <div className="skrbslike">
        <img className="slikalevo" src={imageLevo} alt="Pretirana skrb" />
        <img className="slikadesno" src={imageDesno} alt="Pretirana skrb" />
        <img className="slikaspodaj" src={imageSpodaj} alt="Pretirana skrb" />
      </div>

    </div>
  );

}


export default Skrb;	   