import React from "react";
import HeadTitle from "../components/about-me/HeadTitle";
import BlogFilters from "../components/blog/BlogFilters";
import BlogList from "../components/blog/BlogList";
import { Helmet } from "react-helmet";

function Blog(){
    return (
        <dir className="postavitev">
            <Helmet>
                <title>Blog</title>
                <meta name="description" content="Preberite najnovejše članke in vpoglede iz sveta hipnoterapije. Odkrijte koristne nasvete za vaše mentalno zdravje." />
                <meta name="keywords" content="Blog, Maja Dobravec, Hipnoterapija, Mentalno zdravje, Nasveti, Članki, Terapija, Osebna rast" />
                <meta property="og:title" content="Blog" />
                <meta property="og:description" content="Preberite najnovejše članke in vpoglede iz sveta hipnoterapije. Odkrijte koristne nasvete za vaše mentalno zdravje." />
                <meta property="og:url" content="https://www.hipnoterapijamajad.si/blog" />
                <meta property="og:type" content="website" />
            </Helmet>
            <HeadTitle title={['Blog']} />
            <BlogFilters />
            <BlogList />
        </dir>
        
    )
}

export default Blog;