import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { RaziskaveContext } from '../../context/RaziskaveContext';

function RaziskaveFilters() {
  const { posts, updateFilter } = useContext(RaziskaveContext);

  // Extract unique categories from posts
  const categories = [...new Set(posts.map(post => post.Category))];

  const handleCategoryChange = (e) => {
    updateFilter({ category: e.target.value });
  };

  const handleSearchChange = (e) => {
    updateFilter({ searchText: e.target.value });
  };

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div>
        <Form.Select aria-label="Category dropdown" onChange={handleCategoryChange}>
          <option value="">All Categories</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>{category}</option>
          ))}
        </Form.Select>
      </div>
      {/*<div>   TLE je text filter
        <Form.Control type="text" placeholder="Search by title" onChange={handleSearchChange} />
      </div>*/}
    </div>
  );
}

export default RaziskaveFilters;