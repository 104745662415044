import React from 'react';
import './SpotsGrid.css'; // Make sure to create a corresponding CSS file

// A functional component that accepts the number of spots as props
const SpotsGrid = ({ rows, columns }) => {
    const spots = Array.from({ length: rows * columns });
  
    return (
      <div 
        className="spots-grid" 
        style={{ '--rows': rows, '--columns': columns }} // Pass rows and columns as CSS variables
      >
        {spots.map((_, index) => (
          <div key={index} className="spot"></div>
        ))}
      </div>
    );
  };

export default SpotsGrid;
