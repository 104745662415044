import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CmsBlogList.css'; // Ensure you have this CSS file in your project

function CmsBlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/blogs');
        console.log(`Status: ${response.status}, OK: ${response.ok}`);
        if (!response.ok) {
            throw new Error('Failed to fetch posts');
        }
        let data = await response.json();
        data = data.sort((a, b) => {
          const getLastNumber = (postId) => parseInt(postId.split('_').pop());

          return getLastNumber(b.PostId) - getLastNumber(a.PostId);
      });
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div className="blog-list-loading">Loading...</div>;
  if (error) return <div className="blog-list-error">Error: {error}</div>;

  return (
    <div className="cms-blog-container">
      <h2 className="blog-list-title">Blog Objave</h2>
      <ul className="blog-list">
        {blogs.map(blog => (
          <li key={blog.PostId} className="blog-list-item">
            <Link to={`/cms/blog/${blog.PostId}`} className="blog-list-link">{blog.Title} - {blog.CreationDate}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CmsBlogList;
