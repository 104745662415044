import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as necessary
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { isAuthenticated, login } = useAuth(); // Assuming useAuth provides isAuthenticated
    const [errorMessage, setErrorMessage] = useState('');


    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/cms'); // Redirect to /cms if already authenticated
        }
    }, [isAuthenticated, navigate]); // Depend on isAuthenticated and navigate

    const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const success = await login(username, password); // Await the promise returned by the login function
        if (success) {
            navigate('/cms'); // Redirect to the CMS page on successful login
        } else {
            setErrorMessage('Napačno uporabniško ime ali geslo'); // Update error message state to display the error
        }
    } catch (error) {
        // If login throws an error, handle it here
        setErrorMessage('Login failed due to an unexpected error'); // Update the error message to indicate a failure due to an unexpected error
    }
};

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
                <div className="input-container">
                <label htmlFor="username" className="login-label">Uporabniško ime</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Uporabniško ime"
                        className="login-input"
                    />
                </div>
                <div className="input-container">
                <label htmlFor="password" className="login-label">Geslo</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Geslo"
                        className="login-input"
                    />
                </div>
                <button type="submit" className="login-button">Prijava</button>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>
        </div>
    );
};

export default Login;
