import React from "react";

import HeadTitle from "../components/about-me/HeadTitle";
import FirstSection from "../components/about-me/FirstSection";
import SecondSection from "../components/about-me/SecondSection";
import { Helmet } from "react-helmet";

function About() {

    return (
        <dir className='postavitev'>
            <Helmet>
                <title>O meni</title>
                <meta name="description" content="Spoznajte Majo Dobravec, strokovnjakinjo za hipnoterapijo. Preberite več o njeni poti, izkušnjah in strokovnem znanju." />
                <meta name="keywords" content="O meni, Maja Dobravec, Hipnoterapija, Terapevt, Strokovnjak za hipnozo, Mentalno zdravje, Osebna zgodba, Terapevtske izkušnje" />
                <meta property="og:title" content="O meni" />
                <meta property="og:description" content="Spoznajte Majo Dobravec, strokovnjakinjo za hipnoterapijo. Preberite več o njeni poti, izkušnjah in strokovnem znanju." />
                <meta property="og:url" content="https://www.hipnoterapijamajad.si/omeni" />
                <meta property="og:type" content="website" />
            </Helmet>

            <HeadTitle title="O meni" />
            <FirstSection />
            <SecondSection />
        </dir>
    )
}

export default About;