import React from "react";
import { Helmet } from "react-helmet";
import KontaktContent from "../components/kontakt/KontaktContent";

function Kontakt() {
    return (
        <>
            <Helmet>
                <title>Kontakt</title>
                <meta name="description" content="Kontaktirajte Majo Dobravec za več informacij o hipnoterapiji in storitvah. Sem tukaj, da vam pomagam." />
                <meta name="keywords" content="Kontakt, Maja Dobravec, Hipnoterapija, Stik, Informacije, Pomoč, Mentalno zdravje, Terapija s hipnozo" />
                <meta property="og:title" content="Kontakt" />
                <meta property="og:description" content="Kontaktirajte Majo Dobravec za več informacij o hipnoterapiji in storitvah. Sem tukaj, da vam pomagam." />
                <meta property="og:url" content="https://www.hipnoterapijamajad.si/kontakt" />
                <meta property="og:type" content="website" />
            </Helmet>
            <KontaktContent />
        </>
    );
}

export default Kontakt;
