import React, { useContext } from 'react';
import './ClankiSection.css'; // Import CSS for styling
import { BlogContext } from '../../context/BlogContext';
import { Link, useNavigate } from 'react-router-dom';

function ClankiSection() {


  const { posts, loading } = useContext(BlogContext);

  const navigate = useNavigate();

  const handleClick = (blogId) => {
    navigate(`/blog/${blogId}`);
  };


  return (
    <div className="clanki-section">
      {posts && posts.length > 0 && (
        <>
          <h1 className='yt-title dancingfont'><strong>Blog članki</strong></h1>
          <div className="article-container">
            <div className="article" onClick={() => handleClick(loading ? "" : posts[0].PostId)}>
              <div className="article-image-container image-left">
                <img src={loading ? "" : posts[0].ImageSrc} alt="Article 1" className="article-image" />
              </div>
              <div className="article-details">
                <div className='article-desni'>
                  <h3>{loading ? "" : posts[0].Title}</h3>
                  <p>{loading ? "" : posts[0].Content[0].paragraphs[0].length > 200 ? `${posts[0].Content[0].paragraphs[0].slice(0, 180)}...` : posts[0].Content[0].paragraphs[0]}</p>
                </div>
              </div>
            </div>
            {posts.length > 0 && (
              <div className="article" onClick={() => handleClick(loading ? "" : posts[1].PostId)}>
                <div className="article-details">
                  <div className='article-levi'>
                    <h3>{loading ? "" : posts[1].Title}</h3>
                    <p>{loading ? "" : posts[1].Content[0].paragraphs[0].length > 200 ? `${posts[1].Content[0].paragraphs[0].slice(0, 180)}...` : posts[1].Content[0].paragraphs[0]}</p>
                  </div>
                </div>
                <div className="article-image-container image-right">
                  <img src={loading ? "" : posts[1].ImageSrc} alt="Article 1" className="article-image" />
                </div>
              </div>
            )}
            {/*Add more articles as needed */}
          </div>
          <Link to="/blog" className="btn btn-light button">Vsi članki</Link>
          <hr className='hr-styled' />
        </>
      )}
    </div>
  );
}

export default ClankiSection;
