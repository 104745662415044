import React from "react";
import "../Hipnoterapija.css";
import SpotsGrid from "../../shared/SpotsGrid";
import imageSrc from "../../../assets/images/blokade.jpg";


function Blokade() {
    return (
        <div className="blokade">
            <div class="content-blok">
                <div className="blokadeslika">
                    <img src={imageSrc} alt="Blokade" />
                </div>
                <h4 className="dancingfont"><strong>Situacije, ki aktivirajo čustveno blokado so lahko:</strong></h4>
                <ul>
                    <li>Travmatična izkušnja (nesreča, posilstvo, napad, nezvestoba…)</li>
                    <li>Nenadna smrt ljubljene osebe</li>
                    <li>Razhod ali izguba partnerja</li>
                    <li>Odpoved ali sprememba statusa zaposlitve</li>
                    <li>Diagnoza resne ali kronične bolezni</li>
                    <li>Nenadna selitev drugam</li>
                </ul>
            </div>
            <h4 className="dancingfont"><strong>Znaki, da morda doživljate čustveno blokado, so:</strong></h4>
            <ul>
                <li>Izogibanje ljudem ali družbenim situacijam</li>
                <li>Povečanje ravni anksioznosti ali stresa</li>
                <li>Pomanjkanje motivacije</li>
                <li>Občutek negativnih čustev z večjo intenzivnostjo kot prej (občutki kot so zavist, ljubosumje, kritika, krivda, jeza, strah, žalost…)</li>
                <li>Skrb na različnih področjih</li>
                <li>Težave pri odločanju</li>
            </ul>
            <dir className="ozadje mb-5">
                <div className="spots4">
                    <SpotsGrid rows={11} columns={10} />
                </div>
                <div className="spots5">
                    <SpotsGrid rows={11} columns={10} />
                </div>
                <p><strong className="dancingfont fonts">Čustvena blokada</strong> se aktivira v določenih situacijah, da ublaži vpliv negativne situacije ali dogodka, ki je za nas lahko zelo boleč. V resnici je to obrambni mehanizem možganov, ki ščiti naš um pred tem, kar bi lahko občutili v tej situaciji.</p>
                <p><strong className="dancingfont fonts">Čustvena blokada</strong> pomeni nezmožnost normalnega izražanja čustev med nami in okolico (tlačenje čustev, zgodi se izbruh le teh v skrajni situaciji, ko se čustev ne da več zadrževati).</p>
                <p>So vaše čustvene blokade načele vaše zdravje? Ali pa vas ovirajo pri vaših odnosih, komunikaciji, uresničitvi ciljev…?</p>
            </dir>
            <h4 className="dancingfont"><strong>Delo v stanju hipnoze:</strong></h4>
            <p>S pomočjo hipnoze lahko ugotovite vzrok blokade, jo ozavestite in nevtralizirate. Temu sledi transformacija v lepšo prihodnost kjer so stvari takšne kot si želite, da so.</p>
            <h4 className="dancingfont"><strong>Rezultat:</strong></h4>
            <p>Negativne misli zamenjajo pozitivne.</p>
            <p>Sprejemate čustvene reakcije takšne kot so, brez da jih konstantno nadzirate. Ko je čas za jokanje, takrat jokate, ko je čas za jezo, takrat ste jezni, žalost delite z drugimi in se ne zapirate. Naučite se deliti emocije s svojimi bližnjimi in drugimi!</p>
            <p>Pride do olajšanja duše in ste bolj funkcionalni!</p>
        </div>
    )

}

export default Blokade;