import React from 'react';
import './Omeni.css'; // Import your CSS file for styling
import BrownField from '../shared/BrownField';
import imageProfil3 from "../../assets/images/omeni3.jpg"
import SpotsGrid from '../shared/SpotsGrid';

function SecondSection() {
    const paragraphs = [
        "Tudi sama sem ponosna na spremembe, ki sem jih naredila v svojem življenju, kot je večja motivacija, odločnost, več volje, dvig samozavesti, izboljšanje samopodobe, s čimer sem naredila velik premik in tako začela ustvarjati kot samostojna podjetnica, spremenilo pa se je še kaj drugega.",
        "Te spremembe so odraz sprememb moje miselnosti, prepričanj in spremembe vrstnega reda vrednot."
      ];
      const secondBrown = [
        "Sem praktik hipnoze in certificirana hipnoterapevtka, za kar sem bila poslana na ta svet. To delam s strastjo.",
        "Moje delo je delo z ljudmi in osrečevanje ljudi s pozitivno energijo in iskanjem rešitev za njihove težave.",
        "Moje poslanstvo je pomagati poiskati srečo posameznikov z ozaveščanjem pozitivne miselnosti.",
        "Ljudem pomagam na način, da se rešijo starih in nekoristnih podzavestnih vzorcev , ter jih zamenjajo z novimi koristnejšimi vzorci na podzavestni ravni. V prvi vrsti pa človeku prisluhnem in si zanj vzamem potreben čas.",
        "Torej, s pomočjo hipnoze in hipnoterapije  se dotaknem človekove podzavesti in tako tudi razreševanja stvari, ki so povezane s čustvi."
      ];
  return (
    <div className="text-with-pictures clearfix">
    
    <div className="lowerpictures">
          <img className='omeni3' src={imageProfil3} alt="Profil 3" />
          <p className='omeni3'>"Everything is within your power, and your power is within you!"</p>
    </div>
    <div className="lowertext">  
      {paragraphs.map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
  ))}
  <p>To sem dosegla z <strong>Nevro – lingvističnim programiranjem. Spoznala sem, da lahko na podzavestni ravni spremenimo praktično vse kar želimo.</strong> Le motivirani moramo biti za premike in spremembe v življenju. Tako so me poti vodile naprej in prišla sem v stik s hipnozo.</p>
      
      </div>

      <div className='w-100 mb-2 botsection'>
        <BrownField className="botsection" paragraphs={secondBrown} last={true} />
        <div className="backbotgrid">
          <SpotsGrid rows={11} columns={10} />
        </div>
        
      </div>
      <div>
        <p className='dancingfont fontsize mt-4'><strong>To sem jaz in še mnogo več!</strong></p>
      </div>
    </div>
  );
}

export default SecondSection;