import React from "react";
import LandingSection from "../components/home/LandingSection";
import YouTubeSection from "../components/home/YouTubeSection";
import DejavnostiSection from "../components/home/DejavnostSection";
import PonudbaSection from "../components/home/PonudbaSection";
import ClankiSection from "../components/home/ClanikiSection";
import { Helmet } from 'react-helmet';
import imageSrc1 from '../assets/images/logo.png';

function Home() {
    return (
        <>
            <Helmet>
            <title>Hipnoterapija Maja Dobravec</title>
                <meta name="description" content="Dobrodošli na Hipnoterapija Maja Dobravec, vaš vir za profesionalne storitve hipnoterapije." />
                <meta name="keywords" content="Hipnoterapija, Terapija, Mentalno zdravje, Maja Dobravec, Storitve hipnoterapije, Pomoč pri stresu, Pomoč pri anksioznosti, Terapija proti kajenju, Terapija proti depresiji, Osebnostna rast, Hipnoterapija za otroke, Hipnoterapija za odrasle" />
                <meta property="og:image" content={imageSrc1} />
                <meta property="og:title" content="Hipnoterapija Maja Dobravec" />
                <meta property="og:description" content="Dobrodošli na Hipnoterapija Maja Dobravec, vaš vir za profesionalne storitve hipnoterapije." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.hipnoterapijamajad.si" />
            </Helmet>
            <LandingSection />
            <YouTubeSection />
            <DejavnostiSection />
            <PonudbaSection />
            <ClankiSection />
        </>
    )
}

export default Home;