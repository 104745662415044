import React, { useContext, useEffect, useState } from 'react';
import BlogItem from './BlogItem';
import { BlogContext } from '../../context/BlogContext';
import './Blog.css';



function BlogList() {

  const { posts, loading, error, filter } = useContext(BlogContext);

  let blogs = posts;

  if(filter.category){
      blogs = posts.slice().sort((a, b) => {
  // Check if only 'a' matches the filter category
  if (a.Category === filter.category && b.Category !== filter.category) {
    return -1; // 'a' comes before 'b'
  }
  // Check if only 'b' matches the filter category
  if (b.Category === filter.category && a.Category !== filter.category) {
    return 1; // 'b' comes before 'a'
  }
  // If neither or both 'a' and 'b' match the filter category, maintain their order
  return 0;
});
  }
  console.log(blogs);

  /*const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllPosts = async () => {
    setLoading(true);
    try {
      // Update the URL to match your deployed API Gateway endpoint
      const response = await fetch(`http://127.0.0.1:3000/blogs`);
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPosts();
  }, []); // Empty dependency array means this effect runs once on mount*/

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  /*console.log(posts);

    const blogs =[{
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-16T08:30:00Z"
    },
    {
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-17T08:30:00Z"
    },
    {
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-18T08:30:00Z"
    },
    {
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-19T08:30:00Z"
    },]*/


  // Separate the most recent blog from the rest
  const mostRecentBlog = blogs[0];
  const otherBlogs = blogs.slice(1);

  return (
    <div className="blog-list-container">
      {/* Display the most recent blog on the left */}
      {mostRecentBlog && (
        <div className="most-recent">
          <BlogItem
            imageSrc={mostRecentBlog.ImageSrc}
            blogId={mostRecentBlog.PostId} 
            title={mostRecentBlog.Title}
            date={mostRecentBlog.CreationDate}
            read={mostRecentBlog.ReadingTime}
            category={mostRecentBlog.Category}
            content={mostRecentBlog.Content}
          />
        </div>
      )}

      {/* Display other blogs in a list on the right */}
      <div className="other-blogs">
        {otherBlogs.map((blog, index) => (
          <BlogItem
            key={index}
            blogId={blog.PostId} 
            imageSrc={blog.ImageSrc}
            title={blog.Title}
            date={blog.CreationDate}
            read={blog.ReadingTime}
            category={blog.Category}
            content={blog.Content}
          />
        ))}
      </div>
    </div>
  );
}

export default BlogList;
