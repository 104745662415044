import React from "react";
import imageSrc from "../../../assets/images/odpuscanje.jpg";
import SpotsGrid from "../../shared/SpotsGrid";

function Odpuscanje() {
    return (
        <div className="odpuscanje">
            <p>Preden se odločite razreševati druge težave, ki so se vam nakopičile, bi jaz sama predlagala, da se lotite najprej težke »kategorije« odpuščanja.
                <br />To je <strong>osnova za srečno življenje in zdravo življenje!</strong> Pa še zelo preprosto je, saj se gre za to, da <strong>razbremenite svojo dušo</strong> in od nikogar drugega.
                <br />Torej, odpuščanje pomeni, da to narediš zaradi sebe, <strong>ker se imaš rad</strong>, kar še ne pomeni, da morate vzljubiti tisto osebo ali jo imeti radi, kateri odpuščate. Ta oseba lahko, da se ne bo spremenila, ta trenutek je pomemben za vas in vaše spremembe. Drugih nimamo moči spreminjati. Šele ko se spreminjamo mi, se spreminja okolica okrog nas.
            </p>
            <h4 className="dancingfont sredina mt-4 mb-4"><strong>Izstopite iz te kletke, v vaših rokah je ključ do izhoda! </strong></h4>
            <p>V hipnozi se soočite s seboj in ljudmi, ki jim želite odpustiti na tak način, da se <strong>pogovarjate</strong> s to osebo in <strong>izrazite čustva</strong>, kot jih morda do sedaj še niste. <strong>V trenutku se zvali kamen iz srca!</strong>
                <br />V stanju hipnoze <strong>odmaknete svoj kritični um</strong> in ste tako osredotočeni na situacijo, ki jo želite razrešiti.
            </p>
            <h4 className="dancingfont mt-4"><strong>Proces odpuščanja v hipnozi:</strong></h4>
            <ul>
                <li>Odpuščanje osebi, ki vas je najbolj prizadela</li>
                <li>Odpuščanje vsem, ki so vas kadarkoli prizadeli</li>
                <li>Odpuščanje sebi</li>
            </ul>
            <h4 className="dancingfont sredina mt-4 mb-4"><strong>In tako se za vas začne mirnejše življenje, ko odpustite!</strong></h4>
            <div className="slikaodpdiv">
                <img className="slikaodp" src={imageSrc} alt="Odpuscanje" />
                <div className="frontspot1">
                    <SpotsGrid  rows={11} columns={10} />
                </div>
                
                <div className="frontspot2">
                    <SpotsGrid  rows={11} columns={10} />
                </div>
            </div>
        
        </div>
    )

}

export default Odpuscanje;