
// LayoutWithFooter.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import FooterNorm from "./FooterNorm.jsx"

function LayoutWithFooter(){
  const location = useLocation();

  // Determine if the current path is part of the CMS routes
  const isCMSRoute = location.pathname.startsWith('/cms');
  const isAdminRoute = location.pathname.startsWith('/hipnoterapijaAdmin');

  // Only render the FooterNorm if not in CMS or admin routes
  return (!isCMSRoute && !isAdminRoute) ? <FooterNorm /> : null;
};

export default LayoutWithFooter;
