import React from 'react';
import imageSrc from "../../../assets/images/samohipnozaTab.jpg";


function Samohipnoza() {
    return (
        <dir className="samohipnoza">
            <p><strong>To področje si zasluži posebno pozornost omembe in uporabe!</strong></p>
            <p>Meni je pomagala samohipnoza v trenutku, ko sem se zavedla, da želim iti naprej v življenju. Vsak dan sem šla v samohipnozo za 20 min ali manj,  v ozadju pa sem si zavrtela posnetek s svojo »transformacijo«, tisto kar sem si želela, da se zgodi v prihodnje. In stvari so se začele dogajati v realnosti. </p>
            <img src={imageSrc} alt="Samohipnoza" />
            <h4 className='dancingfont fontsamh'><strong>Samohipnoza</strong></h4>
            <p>V stanje hipnoze te povede hipnotizer, samohipnoza pa je stanje v katero se povedeš sam, s predhodno pomočjo hipnotizerja, ki ti poda <strong>navodila in gesla, da jo lahko izvajaš sam doma.</strong> </p>
            <p>Lahko se jo naučite sami s pomočjo knjige, vendar malo ljudem uspe priti do tega stanja samim, saj ne vedo točno kakšen občutek naj bi to bil, čeprav lahko da je bil v stanju hipnoze.</p>
            <p>Zato se je najlažje naučiti samohipnoze s pomočjo hipnotizerja. <strong>Popelje vas do globokega hipnotičnega stanja in tam naroči vaši podzavesti, naj si to stanje, ta navodila za samohipnozo zapomni in vam poda tudi gesla, ki sprožijo samohipnozo.</strong></p>
            <p><strong>Samohipnoza se uporablja za delo na sebi.</strong> </p>
            <p>Hipnotično stanje je zelo prijetno in ga marsikdo uporablja samo za sprostitev, brez kakršnih koli sugestij. </p>
            <p><strong>Mir in sproščenost, ki ju dosežemo v hipnozi, nas spremljata še kar nekaj časa</strong>, tudi po tistem, ko hipnozo zaključimo.</p>
            <p>Če želite samohipnozo izkoristiti v polni meri, je to stanje, v katerem lahko <strong>poslušate pozitivne sugestije</strong>, ki ste si jih prej posneli. </p>
            <dir className="ozadje">
                <p>Torej samohipnoza s pomočjo sugestij praktično pomaga na vsakem področju vašega življenja, tudi pri <strong>odvajanju kajenja, hujšanju, uspehu v življenju, samopodoba, samozavest, in še in še…
                    <br />Vendar je potrebno hipnozo razumeti in sprejeti, morate biti motivirani za spremembe, v nasprotnem primeru ne deluje.</strong></p>
            </dir>
            <p>Samohipnozo lahko uporabljate <strong>vsak dan</strong> v okolju kjer vas nihče ne moti, lahko tudi samo za 5 min, da se odklopite od okolice ali naglice.</p>
            <h4 className='dancingfont'>Še nekaj nasvetov za uporabo samohipnoze:</h4>
            <ul className="custom-bullets">
                <li>Odločnost</li>
                <li>Fokus</li>
                <li>Koncentracija</li>
                <li>Izboljšanje športnih dosežkov</li>
                <li>Poslovni uspehi</li>
                <li>Več energije</li>
                <li>Priprava na nastop</li>
                <li>Trema</li>
                <li>Koncentracija</li>
            </ul>
            <p>In še in še…</p>
            <p><strong>Ponavljanje je mati modrosti. Ko vsak dan poslušate nek sugestivni program v stanju hipnoze, se ta vtisne v podzavest in postane vaša nova realnost in nova resnica.
                <br />Preprosto super je to slišati, ko imaš vse vajeti v svojih rokah!</strong>
            </p>
        </dir>


    )
}

export default Samohipnoza;