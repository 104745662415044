import React from 'react';
import './Omeni.css'; // Import your CSS file for styling

import BrownField from '../shared/BrownField';
import imageProfil1 from "../../assets/images/omeni1.jpg"
import imageProfil2 from "../../assets/images/omeni2.jpg"

function FirstSection() {

  const firstBrown = ["Moje izkušnje so moja največja vrednost s katerimi potujem v tem življenju. Že nekaj časa raziskujem človeški um in česa je sposoben."];
  return (
    <div className="text-with-pictures clearfix">
      <div className="text">
      <div className='skupina-slik'>
        <div className="pictures">

          <img className='drugiprofil' src={imageProfil2} alt="profil 1"  />
          <img className='prviprofil' src={imageProfil1} alt="profil 2"  />

        </div>

        <h1 className='dancingfont mb-4 ime'><strong>Maja Dobravec</strong></h1>
        <h5><strong>Moje življenje in poslanstvo!</strong></h5>
        </div>
        <p>
          Odkar sem začela delati, je to delo z ljudmi.
          Skozi moja ušesa je šlo ogromno nekih zgodb, srečnih in nesrečnih situacij ljudi.<br />
          Moja vrlina je tako postala »poslušati« posameznike, ki to potrebujejo.<br />
          Pa če pomislim, sem poslušala že svoje starše, včasih pa tudi ne, malo heca.<br />
          Rada imam naravo, še posebej pa me navdihuje gozd in drevesa, ki vzbujajo v meni mir in ustvarjalnost. Živali in rastline pa so zame sveta stvar, saj so dober odraz tega kako narava sama poskrbi za ekološko ravnovesje.
          Večkrat sem razmišljala, zakaj sem na tem svetu, kaj lahko naredim in pustim za seboj. Ugotovila sem, da to že delam vsak dan, ko osrečujem druge preprosto s tem kdor sem tukaj in zdaj.<br />
          Že v otroštvu sem se poglabljala v ljudi in njihovo psihološko stanje.<br />
          Poti so šle križem kražem vse do tukaj kjer sem danes.<br />
          Tudi sama sem imela težke trenutke v življenju, iz teh trenutkov sem potegnila najbolje in jih obrnila sebi v prid.
          Raziskovanje sveta in ljudi me žene k novim spoznanjem in izkušnjam. Verjamem v človekovo zmogljivost prav vsega česar si zada.
        </p>
      </div>
      <div className='w-100 mb-4 mt-4'>
        <BrownField paragraphs={firstBrown} />
      </div>
    </div>
  );
}

export default FirstSection;
