
import './App.css';
import ScrollToTopButton from './components/shared/ScrollToTopButton';
import { AuthProvider } from './context/AuthContext';
import { BlogProvider } from './context/BlogContext';
import { RaziskaveProvider } from './context/RaziskaveContext';
import AppRouter from './router/AppRouter';


function App() {

  return (
    <div className="App">
      <ScrollToTopButton />
      <BlogProvider>
      <RaziskaveProvider>
        <AuthProvider>
          <AppRouter></AppRouter>
        </AuthProvider>
        </RaziskaveProvider>
      </BlogProvider>
    </div>
  );
}

export default App;
