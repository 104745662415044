// AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from '../pages/Home';
import Blog from '../pages/Blog';
import AboutMe from '../pages/AboutMe';
import Ponudba from '../pages/Ponudba';
import NotFound from '../NotFound';
import Hipnoterapija from '../pages/Hipnoterapija';
import Raziskave from '../pages/Raziskave';
import BlogDetails from '../components/blog/BlogDetails';
import BlogForm from '../components/CMS/BlogForm';
import CmsBlogList from '../components/CMS/CmsBlogList';
import CmsBlogDetails from '../components/CMS/CmsBlogDetails';
import Cms from '../pages/CMS/Cms';
import NavbarSelector from '../components/navbar/NavbarSelector';
import ProtectedRoute from './ProtectedRoute';
import Login from '../components/CMS/Login';
import RaziskaveForm from '../components/CMS/RaziskaveForm';
import CmsRaziskaveList from '../components/CMS/CmsRaziskaveList';
import CmsRaziskaveDetails from '../components/CMS/CmsRaziskaveDetails';
import RaziskavaDetails from '../components/raziskave/RaziskaveDetails';
import ScrollToTop from '../hooks/useScrollToTop';
import LayoutWithFooter from '../components/footer/LayoutWithFooter';
import Kontakt from '../pages/Kontakt';
import UnsubscribePage from '../pages/UnsubscribePage';
import Popup from '../components/shared/Popup';
import SitemapRedirect from '../components/shared/SitemapRedirect';


const AppRouter = () => {

  const isProtectedRoute = window.location.pathname.startsWith('/cms');

  return (

    <Router>
      <NavbarSelector />
      <ScrollToTop />
      {!isProtectedRoute && <Popup />} {/* Render Popup only on non-protected routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/omeni" element={<AboutMe />} />
        <Route path="/hipnoterapija" element={<Hipnoterapija />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<BlogDetails />} />
        <Route path="/raziskave" element={<Raziskave />} />
        <Route path="/raziskave/:raziskavaId" element={<RaziskavaDetails />} />
        <Route path="/ponudba" element={<Ponudba />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/hipnoterapijaAdmin" element={<Login />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
        <Route path="/sitemap_index" element={<SitemapRedirect />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/cms" element={<Cms />} />
          <Route path="/cms/blog" element={<BlogForm />} />
          <Route path="/cms/blog-list" element={<CmsBlogList />} />
          <Route path="/cms/blog/:postId" element={<CmsBlogDetails />} />
          <Route path="/cms/raziskave" element={<RaziskaveForm />} />
          <Route path="/cms/raziskave-list" element={<CmsRaziskaveList />} />
          <Route path="/cms/raziskave/:postId" element={<CmsRaziskaveDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <LayoutWithFooter />
    </Router>
  );
};

export default AppRouter;
