import React from 'react';
import ExplanationCMS from '../../components/CMS/ExplinationCMS';



const Cms = () => {
  return (
    <div>
      <ExplanationCMS />
    </div>
  );
};

export default Cms;
