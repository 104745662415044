import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CmsBlogList.css'; // Ensure you have this CSS file in your project

function CmsBlogList() {
  const [raziskave, setRaziskave] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRaziskave = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/raziskave');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        let data = await response.json();
        data = data.sort((a, b) => {
          const getLastNumber = (postId) => parseInt(postId.split('_').pop());

          return getLastNumber(b.RaziskavaId) - getLastNumber(a.RaziskavaId);
      });
        setRaziskave(data);
      } catch (error) {
        console.error("Error fetching raziskave:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRaziskave();
  }, []);

  if (loading) return <div className="blog-list-loading">Loading...</div>;
  if (error) return <div className="blog-list-error">Error: {error}</div>;

  return (
    <div className="cms-blog-container">
      <h2 className="blog-list-title">Raziskave</h2>
      <ul className="blog-list">
        {raziskave.map(raziskava => (
          <li key={raziskava.RaziskavaId} className="blog-list-item">
            <Link to={`/cms/raziskave/${raziskava.RaziskavaId}`} className="blog-list-link">{raziskava.Title} - {raziskava.CreationDate}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CmsBlogList;
