import React from 'react';
import "./Omeni.css";

function HeadTitle({title}) {
    return (
        <div className="head-title">
            <h1 className='menititle'>{title}</h1>
        </div>
    )
}

export default HeadTitle;