import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const UnsubscribePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('Procesiranje zahteve za odjavo...');

    useEffect(() => {
        // This function will be defined inside the effect
        // so it has access to the latest `email` and `token` without needing them in the dependency array.
        const unsubscribe = () => {
          const queryParams = new URLSearchParams(location.search);
          const email = queryParams.get('email');
          const token = queryParams.get('token');
      
          if (!email || !token) {
            setMessage('Invalid or missing email and token.');
            return;
          }
      
          fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/unsubscribe?email=${encodeURIComponent(email)}&token=${token}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(data => {
            //setMessage(data.message);
            setMessage("Uspešna odjava!");
            setTimeout(() => {
              navigate('/'); // Use navigate to redirect
            }, 5000); // Redirect after 5 seconds
          })
          .catch(error => {
            console.error('Error:', error);
            setMessage('Napaka pri odjavi.');
          });
        };
      
        // Call the unsubscribe function defined above
        unsubscribe();
        
        // Now, the effect only runs once on mount because it has an empty dependency array
      }, [location.search, navigate]); // Empty array means this effect will only run once on mount

    return (
        <div>
            <h1>Odjava od E-Novic</h1>
            <p>{message}</p>
        </div>
    );
};

export default UnsubscribePage;
