import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeadTitle from '../about-me/HeadTitle';
import { BlogContext } from '../../context/BlogContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function BlogDetails() {
  const { blogId } = useParams(); // This will get the blogId from the URL
  const { posts, loading, error } = useContext(BlogContext);
  const post = posts.find(post => post.PostId === blogId); // Find the post with the matching ID


  useEffect(() => {
    // Set scroll behavior to smooth when the component mounts
    document.documentElement.style.scrollBehavior = 'smooth';

    // Revert back to auto (default behavior) when the component unmounts
    return () => {
      document.documentElement.style.scrollBehavior = 'auto';
    };
  }, []);
  /*console.log("1 " + blogId);

  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log("2 " + blogId);
  console.log("3 " + blogId);
  useEffect(() => {
    const fetchPostById = async (blogId) => {
      console.log("inside");
      try {
        const response = await fetch(`http://127.0.0.1:3000/getPostById?postId=${blogId}`);
        if (!response.ok) {
          throw new Error('Post not found');
        }
        const data = await response.json();
        setPost(data);
      } catch (error) {
        console.error("Error fetching post:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPostById(blogId);
  }, [blogId]);
  console.log("5 " + blogId);
  console.log(post);*/




  // Fetch the blog details using blogId. Here we are simulating with a placeholder.
  /*const blog = {
    title: "Kdo sem jaz, da sem lahko srečen-a?",
    date: "2024-02-16T08:30:00Z",
    imageSrc: imageSrcTest,
    readTime: "4 min",
    content: [
      {
        "Subtitle": "First Subtitle",
        "Paragraphs": [
          "First paragraph text under first subtitle.",
          "Second paragraph text under first subtitle.\nNew line example."
        ]
      },
      {
        "Subtitle": "Second Subtitle",
        "Paragraphs": [
          "First paragraph text under second subtitle.",
          "Second paragraph text under second subtitle."
        ]
      }
    ]
  };*/

  // Layout for the blog details page
  return (
    <div className="postavitev blog-smooth">
      {post && (
        <Helmet>
          <title>{post.Title} - Hipnoterapija Maja Dobravec</title>
          <meta name="description" content="Blog Objava" />
          <meta name="keywords" content="Hipnoza, Hipnoterapija, Maja Dobravec, Mentalno zdravje, Terapija" />
          <meta property="og:title" content={post.Title} />
          <meta property="og:description" content="Blog Objava" />
          <meta property="og:url" content={`https://hipnoterapijamajad.si/blog/${post.PostId}`} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={post.ImageSrc} />
        </Helmet>
      )}
      <HeadTitle title={['Blog']} />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {post ? (
        <div>
          <div className='blog-info'>
            <p>{post.CreationDate} <FontAwesomeIcon icon={faCircle} className="small-circle" /> <FontAwesomeIcon icon={faHourglassHalf} className='hourglass' /> {post.ReadingTime} min</p>
            <h1><strong>{post.Title}</strong></h1>
            <img src={post.ImageSrc} alt="Blog" />
          </div>
          <div className="blog-body mt-4">
            <div className="blog-navigation">
              <div className='stick'>
                <h3>Vsebina:</h3>
                <ul>
                  {post.Content && post.Content.map((section, index) => (
                    section.subtitle && (
                      <li key={index} className="content-link">
                        <a href={`#subtitle-${index}`}>{section.subtitle}</a>
                      </li>
                    )
                  ))}
                </ul>
                <Link to="/blog" className="btn btn-light button">Vsi članki</Link>
              </div>
            </div>
            <div className="blog-content">
              {post.Content && post.Content.map((section, index) => (
                <div key={index} id={`subtitle-${index}`}>
                  <h2>{section.subtitle}</h2>
                  {section.paragraphs && section.paragraphs.map((paragraph, pIndex) => (
                    <p key={pIndex} style={{ whiteSpace: 'pre-wrap' }}>{paragraph}</p>
                  ))}
                </div>
              ))}
            </div>

          </div>{post.DocumentSrc && (
            <div className="download-section">
              <a href={post.DocumentSrc} download target="_blank" className="btn">Naloži PDF</a>
            </div>
          )}
        </div>
      ) : null}

    </div>
  );
}

export default BlogDetails;
