import React, { useContext, useEffect, useState } from 'react';
import { RaziskaveContext } from '../../context/RaziskaveContext';
import './Raziskava.css';
import RaziskaveItem from './RaziskaveItem';



function RaziskaveList() {

  const { posts, loading, error, filter } = useContext(RaziskaveContext);

  let raziskave = posts;

  if(filter.category){
    raziskave = posts.slice().sort((a, b) => {
  // Check if only 'a' matches the filter category
  if (a.Category === filter.category && b.Category !== filter.category) {
    return -1; // 'a' comes before 'b'
  }
  // Check if only 'b' matches the filter category
  if (b.Category === filter.category && a.Category !== filter.category) {
    return 1; // 'b' comes before 'a'
  }
  // If neither or both 'a' and 'b' match the filter category, maintain their order
  return 0;
});
  }


  /*const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllPosts = async () => {
    setLoading(true);
    try {
      // Update the URL to match your deployed API Gateway endpoint
      const response = await fetch(`http://127.0.0.1:3000/blogs`);
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPosts();
  }, []); // Empty dependency array means this effect runs once on mount*/

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  /*console.log(posts);

    const blogs =[{
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-16T08:30:00Z"
    },
    {
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-17T08:30:00Z"
    },
    {
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-18T08:30:00Z"
    },
    {
        imageSrc:"./ping.jpg",
        title:"Kdo sem jaz, da sem lahko srečen-a?",
        description:"Hipnoterapija",
        date:"2024-02-19T08:30:00Z"
    },]*/


  // Separate the most recent blog from the rest
  const mostRecentRaziskave = raziskave[0];
  const otherRaziskave = raziskave.slice(1);

  return (
    <div className="blog-list-container">
      {/* Display the most recent blog on the left */}
      {mostRecentRaziskave && (
        <div className="most-recent">
          <RaziskaveItem
            imageSrc={mostRecentRaziskave.ImageSrc}
            raziskavaId={mostRecentRaziskave.RaziskavaId} 
            title={mostRecentRaziskave.Title}
            date={mostRecentRaziskave.CreationDate}
            read={mostRecentRaziskave.ReadingTime}
            category={mostRecentRaziskave.Category}
            content={mostRecentRaziskave.Content}
          />
        </div>
      )}

      {/* Display other blogs in a list on the right */}
      <div className="other-blogs">
        {otherRaziskave.map((raziskava, index) => (
          <RaziskaveItem
            key={index}
            raziskavaId={raziskava.RaziskavaId} 
            imageSrc={raziskava.ImageSrc}
            title={raziskava.Title}
            date={raziskava.CreationDate}
            read={raziskava.ReadingTime}
            category={raziskava.Category}
            content={raziskava.Content}
          />
        ))}
      </div>
    </div>
  );
}

export default RaziskaveList;
