import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./CmsBlogDetails.css";

function CmsBlogDetails() {

  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false); // Added for toggle edit mode
  //const [selectedFile, setSelectedFile] = useState(null);
  //const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const { postId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/getPostById?postId=${postId}`);
        if (!response.ok) {
          throw new Error('Blog not found');
        }
        const data = await response.json();
        console.log(data); // Handle the response data
        setBlog(data);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();

  }, [postId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlog({ ...blog, [name]: value });
  };

  const handleSectionChange = (sectionIndex, key, value) => {
    const updatedContent = blog.Content.map((section, index) => {
      if (index === sectionIndex) {
        if (key === 'paragraphs') {
          return { ...section, paragraphs: [value] };
        }
        return { ...section, [key]: value };
      }
      return section;
    });
    setBlog({ ...blog, Content: updatedContent });
  };

  const handleAddSection = () => {
    const newSection = { subtitle: '', paragraphs: [''] };
    setBlog({ ...blog, Content: [...blog.Content, newSection] });
  };

  const handleRemoveSection = () => {
    if (blog.Content.length > 1) {
      const updatedContent = blog.Content.slice(0, -1);
      setBlog({ ...blog, Content: updatedContent });
    }
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (!file) return;

    // Store the file object in the state for later upload
    setBlog({ ...blog, imageFile: file }); // Add a new state property for the file object
    console.log(blog); // Store the selected file
  };

  const handleDocumentChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (!file) return;

    // Store the file object in the state for later upload
    setBlog({ ...blog, imageDocument: file }); // Add a new state property for the file object
    console.log(blog);// Store the selected file
  };

  const uploadImage = async (file) => {
    // Fetch presigned URL from your backend
    const presignedUrlResponse = await fetch('https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/upload-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName: file.name, fileType: file.type }),
    });
    const { url } = await presignedUrlResponse.json();

    // Upload the file to S3
    const uploadResponse = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });

    if (uploadResponse.ok) {
      return url.split('?')[0]; // URL without query parameters
    } else {
      throw new Error('Failed to upload image');
    }
  };



  const validateImageFile = (file) => {
    return !file || (file && file.type.startsWith('image/'));
  };


  const handleSave = async () => {
    setIsLoading(true);

    // Reset validation errors
    setValidationErrors({});



    // Validation
    let errors = {};


    // Validate the image file if one has been selected
    if (!validateImageFile(blog.imageFile)) {
      errors.imageFile = 'Please upload a valid image file.';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setIsLoading(false);
      return; // Stop submission if there are validation errors
    }

    console.log(blog.imageFile)

    if (blog.imageFile) {
      // Assuming you have a function to handle the file upload and it returns the URL

      try {
        const imageUrl = await uploadImage(blog.imageFile);
        console.log(imageUrl);
        //setBlog({ ...blog, ImageSrc: imageUrl }); // Update the blog object with the new image URL
        //setBlog((blog) => ({ ...blog, ImageSrc: imageUrl }));
        blog.ImageSrc = imageUrl;
        console.log(blog.ImageSrc);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert('Error uploading image. Please try again.');
        return;
      }
    }

    console.log(blog.imageDocument);

    if (blog.imageDocument) {
      // Assuming you have a function to handle the file upload and it returns the URL
      try {
        const documentUrl = await uploadImage(blog.imageDocument);
        console.log(documentUrl);
        //setBlog({ ...blog, DocumentSrc: documentUrl }); // Update the blog object with the new image URL
        //setBlog((blog) => ({ ...blog, DocumentSrc: documentUrl }));
        blog.DocumentSrc = documentUrl;
        console.log(blog.DocumentSrc);
      } catch (error) {
        console.error("Error uploading image:", error);
        alert('Error uploading image. Please try again.');
        return;
      }
    }

    


    try {

      /*let updatedBlog = { ...blog };

      // Upload image file if present
      if (blog.imageFile) {
        const imageUrl = await uploadImage(blog.imageFile);
        updatedBlog = { ...updatedBlog, ImageSrc: imageUrl };
      }

      // Upload document file if present
      if (blog.imageDocument) {
        const documentUrl = await uploadImage(blog.imageDocument);
        updatedBlog = { ...updatedBlog, DocumentSrc: documentUrl };
      }*/

      // Now, update the blog state once after all async operations
      //setBlog(updatedBlog);
      console.log(blog);
      // Start loading
      // Replace this URL with your actual endpoint
      const response = await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/update-blog-post/${postId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(blog),
      });

      if (!response.ok) {
        throw new Error('Failed to save blog post');
      }

      alert('Blog updated successfully');
      setIsEditing(false); // Exit editing mode
      // Optionally, fetch the blog details again to ensure the UI is updated
    } catch (error) {
      console.error("Error saving blog:", error);
      alert('Error saving blog. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };




  const handleDelete = async () => {
    // API call to delete the blog post
    setIsLoading(true);
    try {
      await fetch(`https://bcakp7miy1.execute-api.eu-central-1.amazonaws.com/delete-blog-post/${postId}`, { method: 'DELETE' });
      alert('Blog deleted successfully');
      navigate('/cms/blog-list'); // Navigate back to the blog list
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };












  if (loading) return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div>;






  return (

    <div className="cms-blog-details-container">
      {loading ? (
        <div className="loading-indicator">Loading...</div>
      ) : (
        <>
          {isEditing ? (
            <div className="edit-form">
              <div className="form-group">
                <label htmlFor="Title" className="form-label">Naslov:</label>
                <input type="text" id="Title" name="Title" className="form-input" value={blog.Title} onChange={handleInputChange} />
              </div>

              <div className="form-group">
                <label className="form-label">Kategorija:</label>
                <input
                  type="text"
                  className="form-input"
                  name="category"
                  value={blog.Category}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label className="form-label">Čas branja (minute):</label>
                <input
                  type="number"
                  className="form-input"
                  name="ReadingTime"
                  value={blog.ReadingTime}
                  onChange={handleInputChange}
                  min="1"
                />
              </div>
              <div className="form-group">
                <label htmlFor="ImageSrc" className="form-label">Naloži sliko:</label>
                <input type="file" id="imageFile" name="imageFile" onChange={handleFileChange} className="form-input" />
                {validationErrors.imageFile && <div className="error">{validationErrors.imageFile}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="DocumentSrc" className="form-label">Naloži dokument:</label>
                <input type="file" id="documentFile" name="documentFile" onChange={handleDocumentChange} className="form-input" />
              </div>

              {blog.Content.map((section, index) => (
                <div key={index} className="section-edit">
                  <div className="form-group">
                    <label htmlFor={`subtitle-${index}`} className="form-label">Podnaslov:</label>
                    <input type="text" id={`subtitle-${index}`} className="form-input" value={section.subtitle} onChange={(e) => handleSectionChange(index, 'subtitle', e.target.value)} />
                  </div>

                  {section.paragraphs.map((paragraph, pIndex) => (
                    <div key={pIndex} className="form-group">
                      <label htmlFor={`paragraph-${index}-${pIndex}`} className="form-label">Odstavek {pIndex + 1}</label>
                      <textarea id={`paragraph-${index}-${pIndex}`} className="form-textarea" onChange={(e) => handleSectionChange(index, 'paragraphs', e.target.value)} defaultValue={paragraph}></textarea>
                    </div>
                  ))}
                </div>
              ))}
              <div className="section-buttons">

                <button onClick={handleAddSection} className="btn btn-add mr-2">Dodaj odstavek</button>
                <button onClick={handleRemoveSection} className="btn btn-remove mr-2">Odstrani odstavek</button>
                <button onClick={() => setIsEditing(!isEditing)} className="btn btn-cancel mr-2">Prekliči</button>
                <button onClick={handleSave} className="btn btn-save"> {isLoading ? "Nalaganje..." : "Shrani"}</button>

              </div>
            </div>
          ) : (
            <div className="blog-details-view">
              <h2 className="blog-title">{blog.Title}</h2>
              <p className="blog-date">{blog.CreationDate} {blog.ReadingTime} min</p>
              {blog.ImageSrc && <img src={blog.ImageSrc} alt={blog.Title} className="blog-image" />}
              {blog.Content.map((section, index) => (
                <div key={index} className="blog-section">
                  <h3 className="section-subtitle">{section.subtitle}</h3>
                  {section.paragraphs.map((paragraph, pIndex) => (
                    <p key={pIndex} className="section-paragraph">
                      {paragraph.split('\n').map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          {lineIndex < paragraph.split('\n').length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  ))}
                </div>
              ))}
              <div className="action-buttons">
                <button onClick={() => setIsEditing(!isEditing)} className="btn btn-update mr-2">Posodobi</button>
                <button onClick={handleDelete} className="btn btn-delete">{isLoading ? "Brisanje..." : "Izbriši"}</button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CmsBlogDetails;
