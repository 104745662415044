import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ContactButton() {
  /*const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);
    console.log(formProps); // For testing, replace with your email sending logic*/

    // Here, you'd use formProps to send an email.
    // This could be a call to an email API, server endpoint, or similar.

    //setShowModal(false); // Close the modal after submission

    const navigate = useNavigate();

    const handleButtonClick = () => {
      navigate('/kontakt'); // Redirect to the contact page
    };


  return (
    <>
      <Button className='button mt-3' onClick={handleButtonClick}>Naroči se</Button>

{/*      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" name="email" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Your message" name="message" required />
            </Form.Group>

            <Button variant="primary" type="submit">
              Send Message
            </Button>
          </Form>
        </Modal.Body>
      </Modal>*/}
    </>
  );
}

export default ContactButton;
