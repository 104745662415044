import React from 'react';
import './Hipnoterapija.css';
import HeadTitle from '../about-me/HeadTitle';


function TitleSection() {
    return (
        <div className="first-section">
        <div className="head-title">
            <HeadTitle title="Kaj je hipnoterapija?" />
        </div>
        <div className="head-subtitle mb-5"> 
            <p className='mb-0'>Preden se odločite priti na sejo hipnoterapije, je pred vami nekaj besed o temu kaj hipnoza sploh je in v kakšni povezavi je s hipnoterapijo.</p>
        </div>
        {/*<div className='head-contact'>
            <h5>Bi se radi pomenili s strokovnjakom?</h5>
            <Button className="button">Naroči se</Button>
        </div>*/}
        </div>
        )
}

export default TitleSection;